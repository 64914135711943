import { Map } from 'immutable';

export const getRoot = state => state.get('ruleSet') || Map();

export const getRuleSetData = state => getRoot(state).get('data');

export const getRuleSetByID = (state, id) =>
  (getRuleSetData(state).find(i => i.get('id') === id) || Map()).toJS();

export const getScenarioData = state => getRoot(state).get('scenario');

export const getRuleSetFacts = state => getScenarioData(state).get('facts');

export const getRuleOutput = state => getScenarioData(state).get('output').toJS();
