import { Map } from 'immutable';
import { createSelector } from 'reselect';

export const getRoot = state => state.get('quiz') || Map();

export const getQuizzesData = state => getRoot(state).get('quizzes');

export const getQuestionsData = state => getRoot(state).get('questions');

export const getQuizByID = (state, id) =>
  getQuizzesData(state).find(i => i.get('_id') === id) || Map();

export const getQuizByKey = (state, key) =>
  getQuizzesData(state).find(k => k.get('key') === key) || Map();

export const getQuestionByID = (state, id) =>
  getQuestionsData(state).find(i => i.get('id') === id) || Map();

export const getQuestionByKey = (state, key) =>
  getQuestionsData(state).find(k => k.get('key') === key) || Map();

export const getQuizQuestions = createSelector([getQuizByKey, state => state], (quiz, state) => {
  let quizQuestions = [];

  quiz.has('questions') &&
    quiz.get('questions').forEach(q => {
      q.has('key') && quizQuestions.push(getQuestionByKey(state, q.get('key')).toJS());
    });
  return quizQuestions;
});

export const getFactsFromQuiz = createSelector([getQuizQuestions], questions => {
  let facts = [];

  questions.forEach(({ key, dataType, answers }) => {
    const formattedAnswers = answers?.map(a => ({ text: a.text, value: a.value }));
    facts.push({ key, type: dataType, answers: formattedAnswers });
  });

  return facts;
});
