import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import ShadeDataView from '../components/ShadeData';

import { fetchShades, saveShades } from 'state/redux/shade/operations';
import { getProducts, getIsLoading } from 'state/redux/app/selectors';
import { getShadeData } from 'state/redux/shade/selectors';
import { getLevelRange } from 'state/redux/recommendationSet/selectors';

import useFilteredDataGrid from 'hooks/useFilteredDataGrid';

import { formatSelection } from 'utils/formUtils';
import { PRODUCTS } from 'utils/constants';

const ShadeData = () => {
  const dispatch = useDispatch();

  const isLoading = useSelector(state => getIsLoading(state));

  const products = useSelector(state => getProducts(state));
  const {
    [PRODUCTS.RCC.code]: colorKits,
    [PRODUCTS.COLOR_WONDER.code]: colorWonder,
    [PRODUCTS.GLOSS.code]: gloss,
    [PRODUCTS.RRB.code]: rootReboot,
    [PRODUCTS.TGCU.code]: theGreatCoverUp,
    [PRODUCTS.LW.code]: lightworks,
  } = products.toJS();

  const tophatShades = [...colorKits, ...colorWonder];

  const levels = useSelector(state => getLevelRange(state, PRODUCTS.RCC.code));
  const shades = useSelector(state => getShadeData(state));

  const filterNames = ['level', 'showInPlayground'];

  const {
    rows,
    setFilteredData,
    updateData,
    onFilterApply,
    filters,
    commitEdit,
    editCount,
    handleSave,
    handleCancel,
  } = useFilteredDataGrid(shades, filterNames);

  useEffect(() => {
    if (!shades.length) {
      dispatch(fetchShades());
    }
    // eslint-disable-next-line
  }, [shades.length, dispatch]);

  useEffect(() => {
    setFilteredData(shades);
    updateData();
    // eslint-disable-next-line
  }, [updateData, shades]);

  const formattedOptions = options => [
    { value: '', label: '--' },
    ...options.map(l => ({ value: l.code, label: l.name })),
  ];

  const yesNoOptions = [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' },
  ];

  const commonOptions = {
    type: 'singleSelect',
    editable: true,
  };

  const columns = [
    {
      field: 'code',
      flex: 1,
      headerName: 'Shade',
      valueFormatter: p => p.value,
      sortComparator: (a, b) => parseFloat(a) - parseFloat(b),
    },
    {
      field: 'light_works',
      flex: 2,
      headerName: 'Lightworks',
      valueOptions: formattedOptions(lightworks),
      valueFormatter: p => formatSelection(formattedOptions(lightworks), p.value),
      ...commonOptions,
    },
    {
      field: 'great_cover_up',
      flex: 2,
      headerName: 'The Great Cover Up',
      valueOptions: formattedOptions(theGreatCoverUp),
      valueFormatter: p => formatSelection(formattedOptions(theGreatCoverUp), p.value),
      ...commonOptions,
    },
    {
      field: 'root_reboot',
      flex: 2,
      headerName: 'Root Reboot',
      valueOptions: formattedOptions(rootReboot),
      valueFormatter: p => formatSelection(formattedOptions(rootReboot), p.value),
      ...commonOptions,
    },
    {
      field: 'gloss',
      flex: 1,
      headerName: 'Gloss',
      valueOptions: formattedOptions(gloss),
      valueFormatter: p => formatSelection(formattedOptions(gloss), p.value),
      ...commonOptions,
    },
    {
      field: 'showInPlayground',
      flex: 2,
      headerName: 'Show in Playground?',
      valueOptions: yesNoOptions,
      valueFormatter: p => formatSelection(yesNoOptions, p.value),
      ...commonOptions,
    },
    {
      field: 'showInAdvisor',
      flex: 2,
      headerName: 'Show in Color Advisor?',
      valueOptions: yesNoOptions,
      valueFormatter: p => formatSelection(yesNoOptions, p.value),
      ...commonOptions,
    },
    {
      field: 'customTone',
      flex: 1,
      headerName: 'Tone',
      valueFormatter: p => p.value,
      editable: true,
    },
  ];

  const filterProperties = [
    {
      formattedKey: 'shade level',
      multiple: true,
      key: 'level',
      items: levels.length
        ? levels.map(l => ({
            label: l,
            value: l,
          }))
        : [],
    },
    {
      formattedKey: 'show in playground?',
      key: 'showInPlayground',
      clearable: true,
      items: [
        { label: 'yes', value: 'true' },
        { label: 'no', value: 'false' },
      ],
    },
  ];

  // sync *all* current row data to DB
  const syncProducts = () => {
    const products = tophatShades?.map(row => {
      const currentShade = shades.find(s => s.code === row.code);
      const obj = {
        code: row.code,
        level: currentShade?.level || parseInt(row.code.replace(/[^0-9.]/g, '')),
        showInPlayground:
          typeof currentShade?.showInPlayground !== 'undefined'
            ? Boolean(currentShade.showInPlayground)
            : true,
        showInAdvisor:
          typeof currentShade?.showInAdvisor !== 'undefined'
            ? Boolean(currentShade.showInAdvisor)
            : true,
        swatch: row.swatch ? row.swatch.url : undefined,
      };
      return { id: row.code, fields: obj };
    });

    if (products.length === 0) {
      console.log('no shade data from tophat');
      return;
    }
    console.log(products.map(p => p.fields));
    dispatch(saveShades(products));
  };

  return (
    !isLoading && (
      <ShadeDataView
        columns={columns}
        rows={rows}
        filterProperties={filterProperties}
        selectedFilters={filters}
        onFilter={onFilterApply}
        onEdit={commitEdit}
        onSave={() => handleSave(saveShades)}
        unsavedEdits={editCount}
        onCancel={handleCancel}
        productSync={syncProducts}
      />
    )
  );
};

export default ShadeData;
