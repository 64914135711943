import React from 'react';
import PropTypes from 'prop-types';
import { useDrag, useDrop } from 'react-dnd';

import {
  ListItem,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@mui/material';

import DragHandleIcon from '@mui/icons-material/DragHandle';
import DeleteIcon from '@mui/icons-material/Delete';
import NewReleasesIcon from '@mui/icons-material/NewReleases';

const Rule = ({ rule, index, moveRule, findRule, selectRule, selectedId, deleteRule, edited }) => {
  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      type: 'rule',
      item: { id: rule.id, index },
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => {
        const { id: droppedId, index } = item;
        const didDrop = monitor.didDrop();
        if (!didDrop) {
          moveRule(droppedId, index);
        }
      },
    }),
    [rule.id, index, moveRule]
  );
  const [, drop] = useDrop(
    () => ({
      accept: 'rule',
      canDrop: () => false,
      hover({ id: draggedId }) {
        if (draggedId !== rule.id) {
          const { index: overIndex } = findRule(rule.id);
          moveRule(draggedId, overIndex);
        }
      },
    }),
    [findRule, moveRule]
  );
  const opacity = isDragging ? 0 : 1;
  return (
    <ListItem
      ref={preview}
      style={{ opacity }}
      selected={selectedId === rule.id}
      onClick={() => selectRule(rule.id)}
      divider
      disablePadding
      secondaryAction={
        <>
          <IconButton edge="end" aria-label="delete" onClick={e => deleteRule(e, rule.id)}>
            <DeleteIcon />
          </IconButton>
        </>
      }
    >
      <ListItemButton>
        <ListItemIcon ref={node => drag(drop(node))}>
          <DragHandleIcon style={{ cursor: 'grab' }} />
        </ListItemIcon>
        {edited && (
          <Tooltip title="Save Rule Set to Apply Changes">
            <NewReleasesIcon sx={{ mr: 2 }} />
          </Tooltip>
        )}
        <ListItemText primary={rule.name} />
      </ListItemButton>
    </ListItem>
  );
};

Rule.propTypes = {
  rule: PropTypes.object,
  index: PropTypes.number,
  moveRule: PropTypes.func,
  findRule: PropTypes.func,
  selectRule: PropTypes.func,
  deleteRule: PropTypes.func,
  selectedId: PropTypes.number,
  edited: PropTypes.bool,
};

export default Rule;
