import React from 'react';
import { Box, Container, Typography } from '@mui/material';

const DashboardView = () => (
  <Container maxWidth="lg">
    <Box sx={{ pt: 3 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        I am the Dashboard
      </Typography>
    </Box>
  </Container>
);

export default DashboardView;
