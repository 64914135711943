import React from 'react';
import PropTypes from 'prop-types';

import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { TextField } from 'mui-rff';

import {
  Container,
  Grid,
  Box,
  Card,
  CardContent,
  CardHeader,
  MenuItem,
  Button,
  CardActions,
  Paper,
  Typography,
  Divider,
} from '@mui/material';

import ScenarioOutput from './ScenarioOutput';

import PlayArrowIcon from '@mui/icons-material/PlayArrow';

// TODO: add JSON input

const ScenariosView = ({ factSelect, answerSelect, onSubmit, results }) => {
  return (
    <Container maxWidth="xl" sx={{ mb: 4 }}>
      <Box sx={{ pt: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <Form
              onSubmit={onSubmit}
              mutators={{
                ...arrayMutators,
              }}
              render={({ handleSubmit, submitting }) => {
                return (
                  <form onSubmit={values => handleSubmit(values)} noValidate>
                    <Card>
                      <CardHeader
                        title="Scenarios"
                        subheader="Test various combinations of quiz answers to find target level"
                      ></CardHeader>
                      <CardContent>
                        {factSelect.map((fact, i) => {
                          return (
                            <TextField
                              label={fact}
                              name={fact}
                              id="select"
                              key={i}
                              sx={{ mb: 2, minWidth: 120 }}
                              className="filter-control"
                              size="small"
                              fullWidth
                              select
                            >
                              {answerSelect(fact)?.map(val => (
                                <MenuItem key={`${val.value}`} value={val.value}>
                                  {val.value.toString()}
                                </MenuItem>
                              ))}
                            </TextField>
                          );
                        })}
                      </CardContent>
                      <CardActions>
                        <Button
                          size="medium"
                          sx={{ marginLeft: 'auto' }}
                          variant="contained"
                          color="secondary"
                          disabled={submitting}
                          startIcon={<PlayArrowIcon />}
                          type="submit"
                        >
                          Run Scenario
                        </Button>
                      </CardActions>
                    </Card>
                  </form>
                );
              }}
            />
          </Grid>
          <Grid item xs={7}>
            <Card>
              <CardHeader title={`Rule Engine Output`} />
              <CardContent className="rule-output">
                <Paper sx={{ pl: 1 }}>
                  {results.rules ? (
                    <Grid container p={1} spacing={1}>
                      {results?.rules.map((rule, i) => (
                        <ScenarioOutput key={i} output={rule} />
                      ))}
                      <Grid item xs={12}>
                        <Divider sx={{ pt: 1 }} />
                        <Typography variant="h6" component="div" sx={{ py: 1 }}>
                          <strong>TARGET</strong>:{' '}
                          {`LEVEL: ${results?.target.LEVEL} (MIN: ${results?.target.MIN} MAX: ${results?.target.MAX})`}
                        </Typography>
                      </Grid>
                    </Grid>
                  ) : null}
                </Paper>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

ScenariosView.propTypes = {
  factSelect: PropTypes.array,
  answerSelect: PropTypes.func,
  onSubmit: PropTypes.func,
  results: PropTypes.object,
};

export default ScenariosView;
