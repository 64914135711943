import { handleActions } from 'redux-actions';
import { fromJS } from 'immutable';

export const defaultState = fromJS({
  data: [],
});

export default handleActions(
  {
    SHADE: {
      DATA: {
        SET: (state, { payload }) =>
          state.set(
            'data',
            payload.map(p => ({ ...p, id: p.code }))
          ),

        UPDATE: (state, { payload }) => {
          const idx = state.get('data').findIndex(shade => shade.code === payload.code);
          return state.mergeIn(['data', idx], payload.fields);
        },
      },
    },
  },
  defaultState
);
