import { createActions } from 'redux-actions';

const actions = createActions({
  RECOMMENDATIONSET: {
    DATA: {
      SET: data => data,
    },
    LEVELS: {
      SET: data => data,
    },
  },
});

export default actions.recommendationset;
