import recommendations from './actions';
import { getRecommendationData } from './selectors';
import { recArrayToObject, recObjectToArray } from './transformers';
import app from 'state/redux/app/actions';
import api from 'api';

export const fetchRecommendationsByLevel = (recommendationSetId, level) => async dispatch => {
  dispatch(app.ui.loading.on());
  let recs = [],
    err = null;
  try {
    const response = await api.advisor.recommendation.get({
      params: { recommendationSetId, level },
    });
    if (response.data && response.data.length) {
      recs = response.data;
    }
  } catch (error) {
    err = error;
    dispatch(app.ui.snackbar.set({ type: 'error', message: error.message, open: true }));
  }
  dispatch(recommendations.data.set(recs));
  dispatch(app.ui.loading.off());
  return err;
};

export const cleanRecommendations = () => async dispatch => {
  dispatch(app.ui.loading.on());
  dispatch(recommendations.data.set([]));
  dispatch(app.ui.loading.off());
};

export const saveRecommendations = items => async (dispatch, getState) => {
  dispatch(app.ui.loading.on());

  const state = getState();
  const data = items.map(item => ({
    id: item.id,
    tones: item.fields,
  }));
  let err = null;
  try {
    const response = await api.advisor.recommendation.patch({ data });

    const currentRecommendations = getRecommendationData(state);
    const recObjs = recArrayToObject(currentRecommendations);

    const newRecs = recObjs ? JSON.parse(JSON.stringify(recObjs)) : {};
    const changes = response.data.success;
    for (const change of changes) {
      if (newRecs[change.id]) {
        newRecs[change.id].tones = {
          ...change.tones,
        };
      }
    }
    dispatch(recommendations.data.set(recObjectToArray(newRecs)));

    dispatch(
      app.ui.snackbar.set({
        type: 'success',
        message: 'Recommendations successfully saved!',
        open: true,
      })
    );
  } catch (error) {
    err = error;
    dispatch(app.ui.snackbar.set({ type: 'error', message: error.message, open: true }));
  }
  dispatch(app.ui.loading.off());
  return err;
};
