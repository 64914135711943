export const arrayToObject = (arr, keyField = 'id') => {
  let obj = {};

  arr.forEach(item => {
    obj[item[keyField]] = { ...item };
  });

  return obj;
};

export const objectToArray = obj => {
  return Object.values(obj);
};
