import React, { useEffect } from 'react';
// import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Box, Drawer, List, Toolbar } from '@mui/material';

import NavItem from './NavItem';
import withRouter from 'hooks/withRouter';

const drawerWidth = 240;

const Sidebar = ({ onMobileClose, openMobile, window, subNav }) => {
  const container = window !== undefined ? () => window().document.body : undefined;

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Box sx={{ p: 2 }}>
        <List>
          {subNav.map(item => (
            <NavItem
              href={item.path}
              key={item.displayName}
              title={item.displayName}
              icon={item.icon}
            />
          ))}
        </List>
      </Box>
    </Box>
  );

  return (
    <>
      <Drawer
        container={container}
        anchor="left"
        onClose={onMobileClose}
        open={openMobile}
        variant="temporary"
        ModalProps={{
          keepMounted: true, // Better open performance on mobile
        }}
        PaperProps={{
          sx: {
            width: drawerWidth,
          },
        }}
        sx={{
          display: { lg: 'none' },
        }}
      >
        {content}
      </Drawer>
      <Drawer
        anchor="left"
        open
        variant="persistent"
        sx={{
          display: { xs: 'none', lg: 'block' },
        }}
        PaperProps={{
          sx: {
            width: drawerWidth,
          },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: 'auto' }}>{content}</Box>
      </Drawer>
    </>
  );
};

Sidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
  window: PropTypes.object,
  subNav: PropTypes.array,
};

export default withRouter(Sidebar);
