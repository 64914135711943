import shadesActions from './actions';
import app from 'state/redux/app/actions';
import api from 'api';

export const fetchShades = () => async dispatch => {
  dispatch(app.ui.loading.on());

  let err = null;
  try {
    const { data } = await api.advisor.shade.get();
    data.sort((a, b) => parseFloat(a.code) - parseFloat(b.code));
    dispatch(shadesActions.data.set(data));
  } catch (error) {
    err = error;
    dispatch(app.ui.snackbar.set({ type: 'error', message: error.message, open: true }));
  }
  dispatch(app.ui.loading.off());
  return err;
};

export const saveShades = items => async dispatch => {
  dispatch(app.ui.loading.on());
  const data = {};
  items.forEach(item => {
    data[item.id] = item.fields;
    delete item.fields.url;
  });
  try {
    const response = await api.advisor.shade.patch({
      data,
      validateStatus: status => status < 401,
    });

    // *** syncProducts should take care of this ***

    if (response.status === 400 || response.status === 207) {
      const insertCodes = response.data.failed
        .filter(f => f.reason.includes('Shade not found for ID'))
        .map(f => f.code);

      const createData = [];
      insertCodes.forEach(code => {
        createData.push({
          code,
          ...data[code],
        });
      });

      await api.advisor.shade.post({ data: createData });
    }
    dispatch(fetchShades());
    dispatch(
      app.ui.snackbar.set({
        type: 'success',
        message: 'Shade data successfully saved!',
        open: true,
      })
    );
    dispatch(app.ui.loading.off());
    return null;
  } catch (error) {
    dispatch(app.ui.snackbar.set({ type: 'error', message: error.message, open: true }));
    dispatch(app.ui.loading.off());
    return error;
  }
};
