import recommendationSets from './actions';
import api from 'api';
import app from 'state/redux/app/actions';

export const fetchRecommendationSets = () => async dispatch => {
  dispatch(app.ui.loading.on());
  let err = null;
  try {
    const { data } = await api.advisor.recommendationSet.get();
    dispatch(recommendationSets.data.set(data));
  } catch (error) {
    err = error;
    dispatch(app.ui.snackbar.set({ type: 'error', message: error.message, open: true }));
  }
  dispatch(app.ui.loading.off());
  return err;
};
