import ruleSets from './actions';
import app from 'state/redux/app/actions';
import api from 'api';

export const fetchRuleSets = () => async dispatch => {
  dispatch(app.ui.loading.on());
  let err = null;
  try {
    const { data } = await api.advisor.ruleSet.get();

    // tag rules w/ ID and pull all facts used for scenarios
    let factsUsed = [];
    data.forEach(datum => {
      datum.rules.forEach((rule, i) => {
        rule.id = i + 1;
        rule.constraints.forEach(c => {
          if (!factsUsed.includes(c.fact) && datum.id === 1) {
            factsUsed.push(c.fact);
          }
        });
      });
    });

    dispatch(ruleSets.data.set(data));
    dispatch(ruleSets.scenario.facts.set(factsUsed));
  } catch (error) {
    app.ui.snackbar.set({ type: 'error', message: error, open: true });
  }
  dispatch(app.ui.loading.off());
  return err;
};

export const updateRuleSet = (id, changes) => async dispatch => {
  dispatch(app.ui.loading.on());

  const newRules = changes.map(rule => {
    let formattedRule = {
      name: rule.name,
      description: rule.description,
      constraints: rule.constraints?.map(c => {
        if (c.formula === null) c.formula = [];
        return c;
      }),
      actions: rule.actions?.map(a => {
        if (
          a.formula === null ||
          (a.formula[0]?.action === null && a.formula[1]?.action === null)
        ) {
          a.formula === [];
        }
        return a;
      }),
    };
    return formattedRule;
  });

  let err = null;
  try {
    // only rules are editable at the moment
    const { data } = await api.advisor.ruleSet.id.patch({ id }, { data: { rules: newRules } });
    // tag rules with an ID for sorting
    data.rules.forEach((rule, i) => {
      rule.id = i + 1;
    });

    dispatch(ruleSets.data.update(data));
    dispatch(
      app.ui.snackbar.set({ type: 'success', message: 'Rule Set successfully saved!', open: true })
    );
  } catch (error) {
    err = error;
    dispatch(app.ui.snackbar.set({ type: 'error', message: error.message, open: true }));
  }

  dispatch(app.ui.loading.off());
  return err;
};

export const runScenario = (id, answers) => async dispatch => {
  dispatch(app.ui.loading.on());
  let err = null;
  try {
    const { data } = await api.advisor.rulesEngine.level.id.post(
      { id },
      { data: { answers }, params: { debug: true } }
    );
    console.log(data);
    dispatch(ruleSets.scenario.output.set(data));
  } catch (error) {
    err = error;
    dispatch(app.ui.snackbar.set({ type: 'error', message: error.message, open: true }));
  }
  dispatch(app.ui.loading.off());
  return err;
};
