import { styled } from '@mui/styles';

const Logo = styled('div')({
  margin: '0 auto',
  width: '15.5em',
  height: '2.25em',
  background: 'url("/static/MRlogo-purple.svg") no-repeat left top',
  backgroundSize: '100%',
  backgroundRepeat: 'no-repeat',
  color: '#FFF',
});

export default Logo;
