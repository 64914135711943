import { configureStore } from '@reduxjs/toolkit';
import { routerMiddleware as createRouterMiddleware } from 'connected-react-router/immutable';
import thunkMiddleware from 'redux-thunk';
import { createHashHistory } from 'history';
import { middleware as reduxPackMiddleware } from 'redux-pack';
import { Map } from 'immutable';

import rootReducer from './redux';

export const history = createHashHistory();
export const routerMiddleware = createRouterMiddleware(history);

// const stackTrace = {
//   trace: true,
//   traceLimit: 25,
// };
const middlewares = [thunkMiddleware, reduxPackMiddleware, routerMiddleware];

export const store = configureStore({
  reducer: rootReducer(history),
  preloadedState: Map(),
  devTools: true,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }).concat(middlewares),
});
