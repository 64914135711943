import { createActions } from 'redux-actions';

const actions = createActions({
  RULESET: {
    DATA: {
      SET: data => data,
      UPDATE: data => data,
    },
    SCENARIO: {
      SET: data => data,
      FACTS: {
        SET: data => data,
      },
      OUTPUT: {
        SET: data => data,
      },
    },
  },
});

export default actions.ruleset;
