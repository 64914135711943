import { handleActions } from 'redux-actions';
import { fromJS } from 'immutable';

export const defaultState = fromJS({
  quizzes: [],
  questions: [],
});

export default handleActions(
  {
    QUIZ: {
      QUIZZES: {
        SET: (state, { payload }) => state.set('quizzes', fromJS(payload)),
      },
      QUESTIONS: {
        SET: (state, { payload }) => state.set('questions', fromJS(payload)),
      },
    },
  },
  defaultState
);
