import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    '@global': {
      '*': {
        boxSizing: 'border-box',
        margin: 0,
        padding: 0,
      },
      html: {
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
        height: '100%',
        width: '100%',
      },
      body: {
        height: '100%',
        width: '100%',
        fontFamily: '"Averta-Regular",sans-serif',
        fontWeight: 'normal',
        fontStyle: 'normal',
        color: '#343434',
        backgroundColor: '#fff',
        fontSize: '14px',
        letterSpacing: '.03em',
      },
      a: {
        textDecoration: 'none',
      },
      '#root': {
        height: '100%',
        width: '100%',
      },
      code: {
        borderRadius: '0.3em',
        backgroundColor: '#f4f6f8',
        padding: '4px 5px 6px',
        lineHeight: '2em',
      },
      '.rule-output p': {
        marginLeft: '20px',
      },
    },
  })
);

const GlobalStyles = () => {
  useStyles();

  return null;
};

export default GlobalStyles;
