import React from 'react';
import PropTypes from 'prop-types';

import { Button, CardContent, Grid, IconButton, MenuItem, Paper } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

import { TextField } from 'mui-rff';

import MultipleSelect from './MultipleSelect';

const RuleInput = ({ factSelect, opSelect, answerSelect, fields, push }) => {
  return (
    <CardContent>
      {fields.map((constraint, i) => (
        <Paper key={i} sx={{ mb: 3, pl: 1 }}>
          <Grid container key={i} justifyContent="space-between">
            <Grid
              container
              item
              spacing={0.5}
              justifyContent="space-between"
              alignItems="center"
              xs={11}
            >
              <Grid item xs={4}>
                <TextField
                  label="Input"
                  name={`${constraint}.fact`}
                  margin="normal"
                  size="small"
                  id="select"
                  select
                >
                  {factSelect.map(fact => (
                    <MenuItem key={`${fact}`} value={fact}>
                      {fact}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Operator"
                  name={`${constraint}.operator`}
                  margin="normal"
                  size="small"
                  id="select"
                  select
                >
                  {opSelect.map(
                    op =>
                      op.type === 'INPUT' && (
                        <MenuItem key={`${op.value}`} value={op.value}>
                          {op.label}
                        </MenuItem>
                      )
                  )}
                </TextField>
              </Grid>
              <Grid item xs={4}>
                {fields.value[i]?.fact &&
                  fields.value[i]?.operator !== 'SET' &&
                  fields.value[i]?.operator !== 'NOTSET' &&
                  (fields.value[i]?.operator === 'EQ' || fields.value[i]?.operator === 'NOTEQ' ? (
                    <MultipleSelect
                      items={answerSelect}
                      constraint={constraint}
                      fields={fields.value[i]}
                    />
                  ) : (
                    <TextField
                      label="Value"
                      name={`${constraint}.value`}
                      margin="normal"
                      size="small"
                      id="select"
                      select
                    >
                      {answerSelect(fields.value[i].fact).map(val => (
                        <MenuItem key={`${val.value}`} value={val.value} margin="normal">
                          {val.value.toString()}
                        </MenuItem>
                      ))}
                    </TextField>
                  ))}
              </Grid>
            </Grid>
            <Grid item xs={1} sx={{ textAlign: 'center', pt: 1.5 }}>
              <IconButton edge="end" aria-label="delete" onClick={() => fields.remove(i)}>
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Paper>
      ))}
      <Button
        variant="outlined"
        startIcon={<AddIcon />}
        onClick={() => push('constraints', undefined)}
      >
        Add
      </Button>
    </CardContent>
  );
};

RuleInput.propTypes = {
  fields: PropTypes.object,
  factSelect: PropTypes.array,
  push: PropTypes.func,
  opSelect: PropTypes.array,
  answerSelect: PropTypes.func,
};

export default RuleInput;
