import React from 'react';
import PropTypes from 'prop-types';

import { Grid, MenuItem, ListSubheader } from '@mui/material';

import { TextField } from 'mui-rff';

const RuleOutputCompare = ({
  factSelect,
  fields,
  opSelect,
  outputSelect,
  formula,
  actionSelect,
  index,
}) => (
  <Grid container spacing={0.5} justifyContent="flex-start" alignItems="center">
    <Grid item xs={2} />
    <Grid item xs={3}>
      <TextField
        label="Action"
        name={`${formula}.action`}
        margin="normal"
        size="small"
        id="select"
        select
      >
        {actionSelect
          .filter(({ key }) => key !== 'SET_COMPARE')
          .map(({ key, label }) => (
            <MenuItem key={key} value={key}>
              {label}
            </MenuItem>
          ))}
      </TextField>
    </Grid>
    {fields.value[index]?.action === 'SET_STATIC' ? (
      <Grid item xs={1}>
        <TextField label="Value" name={`${formula}.value`} margin="normal" size="small" />
      </Grid>
    ) : fields.value[index]?.action === 'SET_FACT' ? (
      <Grid item xs={3}>
        <TextField
          label="Fact"
          name={`${formula}.fact`}
          margin="normal"
          size="small"
          id="select"
          select
        >
          <ListSubheader>Outputs</ListSubheader>
          {outputSelect.map(({ key, label }) => (
            <MenuItem key={key} value={key}>
              {label}
            </MenuItem>
          ))}
          <ListSubheader>Inputs</ListSubheader>
          {factSelect.map(fact => (
            <MenuItem key={`${fact}`} value={fact}>
              {fact}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    ) : fields.value[index]?.action === 'SET_FORMULA' ? (
      <>
        <Grid item xs={3}>
          <TextField
            label="Input"
            name={`${formula}.fact`}
            margin="normal"
            size="small"
            id="select"
            select
          >
            {factSelect.map(fact => (
              <MenuItem key={`${fact}`} value={fact}>
                {fact}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={3}>
          <TextField
            label="Operator"
            name={`${formula}.operator`}
            margin="normal"
            size="small"
            id="select"
            select
          >
            {opSelect.map(
              op =>
                op.type === 'OUTPUT' && (
                  <MenuItem key={`${op.value}`} value={op.value}>
                    {op.label}
                  </MenuItem>
                )
            )}
          </TextField>
        </Grid>
        <Grid item xs={1}>
          <TextField label="Value" name={`${formula}.value`} margin="normal" size="small" />
        </Grid>
      </>
    ) : null}
  </Grid>
);

RuleOutputCompare.propTypes = {
  fields: PropTypes.object,
  formula: PropTypes.string,
  factSelect: PropTypes.array,
  opSelect: PropTypes.array,
  outputSelect: PropTypes.array,
  actionSelect: PropTypes.array,
  index: PropTypes.number,
};

export default RuleOutputCompare;
