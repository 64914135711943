import quiz from './actions';
import app from 'state/redux/app/actions';
import api from 'api';

export const fetchQuizData = () => async dispatch => {
  let err = null;
  try {
    const { data } = await api.tophat.rest.quizBuilder.getAllQuiz.get();
    dispatch(quiz.quizzes.set(data));
  } catch (error) {
    err = error;
    dispatch(app.ui.snackbar.set({ type: 'error', message: error.message, open: true }));
  }
  return err;
};

export const fetchQuestionData = () => async dispatch => {
  let err = null;
  try {
    const { data } = await api.tophat.rest.quizBuilder.getAllQuestion.get();
    dispatch(quiz.questions.set(data));
  } catch (error) {
    err = error;
    dispatch(app.ui.snackbar.set({ type: 'error', message: error.message, open: true }));
  }
  return err;
};
