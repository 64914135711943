import Axios from 'axios';
import qs from 'qs';

export const GET = 'get';
export const POST = 'post';
export const PATCH = 'patch';
export const DELETE = 'delete';
export const PUT = 'put';

// TODO: make work per environment
const axios = Axios.create({
  baseURL: '/',
  // support queryParams with duplicate names (ex: 'a[0]=b&a[1]=c&a[2]=d')
  paramsSerializer: params => qs.stringify(params, { indices: false }),
});

const buildUrl = (template, params, host) => {
  let finalUrl = template.replace(/:(\w+)/g, (_, p) => {
    if (!params[p]) {
      throw new Error(`Missing url param: '${p}' for endpoint: '${template}'`);
    }
    return params[p];
  });
  if (host) {
    finalUrl = host + finalUrl.replace('/tophat/', '').replace('/advisor/', '');
  }
  return finalUrl;
};

/*
 * makeEndpoints:
 * - creates methods for url endpoint based on list of http methods
 * - endpoint with url params (:id) takes (params: required, config: optional)
 * - endpoint without url params takes only (config: optional)
 * - config: axios config object: https://github.com/axios/axios#request-config
 */
const makeEndpoints = (url, methods, host) =>
  methods.reduce(
    (endpoints, method) => ({
      ...endpoints,
      [method]: /:\w+/.test(url)
        ? (params, config) =>
            axios({
              url: buildUrl(url, params, host),
              method,
              withCredentials: true,
              ...config,
            })
        : config =>
            axios({
              url: buildUrl(url, null, host),
              method,
              withCredentials: true,
              ...config,
            }),
    }),
    {}
  );

/*
 * makeAPI:- generates api object based on list of urls and their methods
 */
const makeAPI = endpoints => {
  return endpoints.reduce((api, { url, methods, host }) => {
    url
      .replace(/^\//, '')
      .replace(/:/g, '')
      .split('/')
      .reduce((acc, curr, idx, list) => {
        if (!acc[curr]) {
          acc[curr] = idx === list.length - 1 ? makeEndpoints(url, methods, host) : {};
        }
        return acc[curr];
      }, api);

    return api;
  }, {});
};

export default makeAPI;
