import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

import SaveBarButton from './SaveBarButton';

const FixedBottom = styled(Box)({
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: '#000000',
  flexDirection: 'row',
  height: '56px',
  zIndex: '1000',
  display: 'flex',
});

const ButtonStack = styled(Stack)({
  alignItems: 'center',
  justifyContent: 'right',
  display: 'flex',
  width: '1200px20px',
});

const TextStack = styled(Stack)({
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
  flexGrow: 1,
});

export default function SaveBar({ buttons, unsavedEdits = 0 }) {
  return (
    <FixedBottom>
      <TextStack spacing={1} sx={{ mr: 2 }} direction="row">
        <Typography sx={{ pl: 5, color: '#FFFFFF' }}>
          {unsavedEdits > 0 && ` ${unsavedEdits} unsaved change${unsavedEdits > 1 ? 's' : ''}`}
        </Typography>
      </TextStack>
      <ButtonStack spacing={1} sx={{ mr: 2 }} direction="row">
        {buttons.map((button, i) => {
          return <SaveBarButton key={i} {...button} />;
        })}
      </ButtonStack>
    </FixedBottom>
  );
}

SaveBar.propTypes = {
  unsavedEdits: PropTypes.number,
  buttons: PropTypes.array,
};
