import { handleActions } from 'redux-actions';
import { fromJS } from 'immutable';

export const defaultState = fromJS({
  data: [],
  levels: [],
});

export default handleActions(
  {
    RECOMMENDATIONSET: {
      DATA: {
        SET: (state, { payload }) => {
          return state.set('data', fromJS(payload));
        },
      },
      LEVELS: {
        SET: (state, { payload }) => state.set('levels', payload),
      },
    },
  },
  defaultState
);
