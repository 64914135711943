import React from 'react';
import PropTypes from 'prop-types';

import { Card, ButtonGroup, Button, CardContent, CardHeader, Stack, Divider } from '@mui/material';

import { Form, FormSpy } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';

import { TextField } from 'mui-rff';

const RuleDetailsView = ({ rule, onSubmit, validate, renderInput, renderOutput, unsavedRule }) => {
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={rule}
      mutators={{
        ...arrayMutators,
      }}
      validate={validate}
      render={({
        handleSubmit,
        submitting,
        pristine,
        form: {
          mutators: { push },
          reset,
        },
      }) => {
        return (
          <form onSubmit={values => handleSubmit(values).then(reset())} noValidate>
            <FormSpy
              subscription={{ pristine: true }}
              onChange={state => (setTimeout(() => unsavedRule(!state.pristine)), 0)}
            />
            <Stack spacing={2}>
              <Card>
                <CardHeader
                  title="Rule Details"
                  action={
                    <ButtonGroup size="medium">
                      <Button disabled={pristine || submitting} onClick={reset}>
                        Discard Changes
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        disabled={pristine || submitting}
                        type="submit"
                      >
                        Save Changes
                      </Button>
                    </ButtonGroup>
                  }
                />
                <CardContent>
                  <TextField label="Name" name="name" fullWidth margin="normal" />
                  <TextField
                    label="Description"
                    name="description"
                    fullWidth
                    multiline
                    margin="normal"
                    rows={4}
                  />
                </CardContent>
                <Divider variant="middle" />
                <CardHeader
                  title="Rule Inputs"
                  subheader={`Constraints applied to color quiz answers, i.e. "if [want_level] equals [lighter]"`}
                ></CardHeader>
                <FieldArray name="constraints">
                  {({ fields }) => renderInput(fields, push)}
                </FieldArray>
                <Divider variant="middle" />
                <CardHeader
                  title="Target Level Outputs"
                  subheader={`Output based on constraints, i.e. "set [Maximum] to forumla [hair_shade] [add] [2]"`}
                ></CardHeader>
                <FieldArray name="actions">{({ fields }) => renderOutput(fields, push)}</FieldArray>
              </Card>
            </Stack>
          </form>
        );
      }}
    />
  );
};

RuleDetailsView.propTypes = {
  rule: PropTypes.object,
  onSubmit: PropTypes.func,
  validate: PropTypes.func,
  renderInput: PropTypes.func,
  renderOutput: PropTypes.func,
  unsavedRule: PropTypes.func,
};

export default RuleDetailsView;
