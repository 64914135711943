import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import ShadeDataView from '../components/ShadeData';

import { saveShades, fetchShades } from 'state/redux/shade/operations';
import { fetchRecommendationSets } from 'state/redux/recommendationSet/operations';
import { getShadeData } from 'state/redux/shade/selectors';
import { getIsLoading } from 'state/redux/app/selectors';
import { getLevelRange, getPartners } from 'state/redux/recommendationSet/selectors';

import useFilteredDataGrid from 'hooks/useFilteredDataGrid';

import { PRODUCTS } from 'utils/constants';

const PartnersData = () => {
  const dispatch = useDispatch();

  const isLoading = useSelector(state => getIsLoading(state));

  const partners = useSelector(state => getPartners(state));
  const levels = useSelector(state => getLevelRange(state, PRODUCTS.RCC.code));
  const shades = useSelector(state => getShadeData(state));
  const [partner, setPartner] = useState({ channel: partners[0]?.id });
  const [showUnsetPartnerProducts, setShowUnsetPartnerProducts] = useState(false);
  const filterNames = ['level'];

  const {
    rows,
    setFilteredData,
    updateData,
    onFilterApply,
    filters,
    commitEdit,
    editCount,
    handleCancel,
    handleSave,
    onCustomFilterFnApply,
  } = useFilteredDataGrid(shades, filterNames);

  useEffect(() => {
    dispatch(fetchRecommendationSets());
    setPartner({ channel: partners[0]?.id });
    // eslint-disable-next-line
  }, [partners.length, dispatch]);

  useEffect(() => {
    if (!shades.length) {
      dispatch(fetchShades());
    }
    // eslint-disable-next-line
  }, [shades.length, dispatch]);

  useEffect(() => {
    setFilteredData(shades);
    updateData();
    // eslint-disable-next-line
  }, [updateData, shades]);

  const filterProperties = [
    {
      formattedKey: 'shade level',
      multiple: true,
      key: 'level',
      items: levels.length
        ? levels.map(l => ({
            label: l,
            value: l,
          }))
        : [],
    },
  ];

  const getPartnerUrl = p => {
    if (p.row.partners.length) {
      const partnerObj = p.row.partners.find(o => o.recommendationSetId === partner.channel);
      if (partnerObj) {
        return partnerObj.url;
      }
    }
  };

  const editPartnerUrl = ({ id, value }) => {
    const partnerData = {
      recommendationSetId: partner.channel,
      url: value,
    };
    const { partners: shadePartners } = shades.find(shade => shade.code === id);
    const editedPartners = JSON.parse(JSON.stringify(shadePartners));
    const idx = shadePartners.findIndex(p => p.recommendationSetId === partner.channel);
    if (editedPartners.length === 0 || idx < 0) {
      editedPartners.push(partnerData);
    } else if (idx >= 0) {
      editedPartners[idx] = { ...partnerData };
    }
    commitEdit({
      id,
      field: 'partners',
      value: editedPartners.filter(x => x.url),
    });
  };

  const columns = [
    {
      field: 'code',
      flex: 1,
      headerName: 'Shade',
      valueFormatter: p => p.value,
      sortComparator: (a, b) => parseFloat(a) - parseFloat(b),
    },
    {
      field: 'url',
      flex: 4,
      headerName: 'URL',
      valueGetter: p => getPartnerUrl(p),
      valueFormatter: p => p.value || 'n/a',
      editable: true,
      cellClassName: 'url',
    },
  ];

  const selectChannel = ({ target }) => {
    setPartner({ channel: target.value });
  };

  const applyCustomFilterFn = () => {
    function partnerProductFilter(hide) {
      return function (shade) {
        let isUrlDefined = false;
        if (Array.isArray(shade.partners)) {
          const partnerData = shade.partners.find(p => p.recommendationSetId == partner.channel);

          isUrlDefined = Boolean(partnerData && partnerData.url);
        }

        return hide ? !isUrlDefined : isUrlDefined;
      };
    }

    onCustomFilterFnApply(partnerProductFilter(showUnsetPartnerProducts));
  };

  useEffect(() => {
    applyCustomFilterFn();
    // eslint-disable-next-line
  }, [partner.channel, showUnsetPartnerProducts]);

  const channelFilter = [
    {
      formattedKey: 'Channel',
      key: 'channel',
      items: partners.map(rec => ({
        label: rec.name,
        value: rec.id,
      })),
    },
  ];

  const onSaveShadeData = () => {
    handleSave(saveShades);

    applyCustomFilterFn();
  };

  return (
    !isLoading && (
      <ShadeDataView
        columns={columns}
        rows={rows}
        filterProperties={filterProperties}
        selectedFilters={filters}
        onFilter={onFilterApply}
        onEdit={editPartnerUrl}
        onSave={onSaveShadeData}
        unsavedEdits={editCount}
        onCancel={handleCancel}
        channelFilter={channelFilter}
        selectedChannel={partner}
        selectChannel={selectChannel}
        canAdd={true}
        onAdd={() => setShowUnsetPartnerProducts(!showUnsetPartnerProducts)}
        showAdd={!showUnsetPartnerProducts}
      />
    )
  );
};

export default PartnersData;
