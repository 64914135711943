export const productsToStore = products => {
  let formattedProducts = {};

  products.forEach(product => {
    let prodObj = {
      name: product.product.name,
      code: product.product.code,
      id: product.product.productId,
      variants: product.variants.map(variant => ({
        name: variant.name,
        code: variant.code,
        id: variant.id,
        active: variant.active,
        visible: variant.visible,
        swatch: variant.metadata.data.toneSwatch,
        urlKey: variant.metadata.data.urlKey,
        tone: variant.metadata.data.colorTone,
        vivid: variant.metadata.data.vivid,
      })),
    };
    formattedProducts[product.product.code] = prodObj;
  });

  return formattedProducts;
};
