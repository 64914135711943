import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from '@mui/material';

import { getNotification } from 'state/redux/app/selectors';

import actions from 'state/redux/app/actions';

const AlertDialog = () => {
  const dispatch = useDispatch();
  const data = useSelector(state => getNotification(state));
  const { message, title, open = false, onDone = () => {}, backButton = true } = data;

  const handleClose = value => {
    onDone(value);
    dispatch(actions.ui.notification.reset());
  };

  return (
    <Dialog
      open={open}
      onClose={() => handleClose(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {backButton && <Button onClick={() => handleClose(false)}>Go Back</Button>}
        <Button onClick={() => handleClose(true)}>Continue</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;
