import { createSelector } from '@reduxjs/toolkit';
import { Map } from 'immutable';

import { getProductByCode } from '../app/selectors';

export const getRoot = state => state.get('recommendationSet') || Map();

export const getRecommendationSetData = state => getRoot(state).get('data');

export const getRecommendationSetByID = (state, recSetId) =>
  getRecommendationSetData(state)
    .toJS()
    .find(recSet => recSet.id === recSetId) || {};

export const getLevelRange = createSelector(getProductByCode, shades => {
  shades = shades.toJS();
  let levels = shades.map(shade => parseInt(shade.code)).filter(level => level <= 10); // Hide level 11+ from side nav

  levels = [...new Set(levels)].sort((a, b) => a - b);

  return levels || [];
});

export const getPartners = createSelector(getRecommendationSetData, recSets => {
  if (recSets.size > 0) {
    return recSets.filter(rs => rs.get('partner')).toJS();
  }
  return [];
});
