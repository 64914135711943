import { handleActions } from 'redux-actions';
import { fromJS } from 'immutable';

export const defaultState = fromJS({
  data: [],
});

export default handleActions(
  {
    RECOMMENDATION: {
      DATA: {
        SET: (state, { payload }) => state.set('data', payload),
      },
    },
  },
  defaultState
);
