import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Alert, Snackbar } from '@mui/material';
import { getSnackbar } from 'state/redux/app/selectors';

import actions from 'state/redux/app/actions';

const SnackBar = () => {
  const dispatch = useDispatch();
  const data = useSelector(state => getSnackbar(state));
  const { message, type, open } = data;

  const handleClose = () => {
    dispatch(actions.ui.snackbar.reset());
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={open}
      onClose={handleClose}
    >
      <Alert severity={type} onClose={handleClose} elevation={6} variant="filled">
        {message}
      </Alert>
    </Snackbar>
  );
};

export default SnackBar;
