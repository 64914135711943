import app from './actions';
import api from 'api';

import { productsToStore } from './transformers';
import { PRODUCTS } from 'utils/constants';

import { fetchQuestionData, fetchQuizData } from 'state/redux/quiz/operations';

const tophatUrl = process.env.REACT_APP_TOPHAT_URL;

export const fetchAllProducts = () => async dispatch => {
  const productPromises = Object.keys(PRODUCTS).map(key => {
    return api.tophat.rest.colorAdvisorAdmin.getDataForEditingComplexProduct.get({
      params: { code: PRODUCTS[key].code },
    });
  });

  let err = null;
  try {
    const productDataArray = await Promise.all(productPromises);

    const productData = productsToStore(productDataArray.map(product => product.data));

    dispatch(app.products.data.set(Object.values(productData)));

    // loop through hair color products and put formatted variants in store
    Object.keys(PRODUCTS).forEach(key => {
      const formatted = productData[PRODUCTS[key].code].variants
        .filter(variant => !variant.name.includes('Color Bar Only'))
        .filter(variant => !variant.code.includes('SHORT'))
        .map(variant => ({
          code: variant.code,
          name: variant.name,
          variantId: variant.id,
          swatch: variant.swatch,
        }))
        .sort((a, b) => parseFloat(a.code) - parseFloat(b.code));
      dispatch(app.products.set({ key: PRODUCTS[key].code, data: formatted }));
    });
  } catch (error) {
    err = error;
    dispatch(app.ui.snackbar.set({ type: 'error', message: error.message, open: true }));
  }
  return err;
};

export const fetchTophatData = () => async dispatch => {
  let err = null;
  try {
    const { data } = await api.tophat.rest.agent.getLoggedInAgent.get();
    dispatch(fetchQuestionData());
    dispatch(fetchQuizData());
    dispatch(fetchAllProducts());
    dispatch(app.agent.set(data));
  } catch (error) {
    err = error;
    if (err.message.includes('403')) {
      const handleClick = value => {
        if (value) window.location = tophatUrl;
      };
      dispatch(
        app.ui.notification.set({
          message: 'Please log into Tophat before proceeding.',
          title: 'Unauthorized',
          open: true,
          onDone: handleClick,
          backButton: false,
        })
      );
    } else {
      dispatch(app.ui.snackbar.set({ type: 'error', message: error.message, open: true }));
    }
  }
  return err;
  // dispatch(app.ui.loading.off());
};

export const clearCache = () => async dispatch => {
  dispatch(app.ui.loading.on());
  try {
    await api.advisor.rulesEngine.clearCache.post();
    dispatch(
      app.ui.snackbar.set({
        type: 'success',
        message: 'Cache successfully cleared!',
        open: true,
      })
    );
  } catch (error) {
    dispatch(app.ui.snackbar.set({ type: 'error', message: error.message, open: true }));
  }
  dispatch(app.ui.loading.off());
};

/**
 * BATCH API requests with a progress count! Batch will handle the interaction
 * with the store: setting items, totals, incrementing, setting retry and so on
 *
 * Structure for batchItems:
 *
 * [{
 *   endpoint: fn, // a preloaded api.thing object e.g. api.user(userID)
 *   verb: str, // the verb inside that object space e.g. 'patch', 'post'
 *   params: obj, // params passed to the call e.g. { id: 678 }
 *   data: obj, // data passed to the call e.g. { tones: { ash: 2NA } }
 * }]
 *
 * */
export const batch =
  (batchItems, onDone = () => {}) =>
  async dispatch => {
    dispatch(app.ui.batch.reset());
    dispatch(app.ui.batch.total.set(batchItems.length));
    dispatch(app.ui.batch.items.set(batchItems));

    const promises = batchItems.map(async ({ endpoint, verb, params = {}, data = {} }) => {
      try {
        await endpoint[verb](params, data);
        dispatch(app.ui.batch.increment());
        dispatch(app.ui.batch.items.shift());
      } catch (error) {
        // TODO: toast error
        console.log(error);
      }
    });

    return Promise.all(promises)
      .then(() => dispatch(onDone()))
      .catch(() => dispatch(app.ui.batch.retry.toggle(true)));
  };
