import React from 'react';

import Page from 'views/layouts/Page';
import { Rules as RulesView, Scenarios as ScenariosView } from 'views/layouts/Rules';
import RecommendationsView from 'views/layouts/Recommendations';
import ShadesView from 'views/layouts/Shades';
import PartnersView from 'views/layouts/Shades/partners';
import DashboardView from 'views/layouts/Dashboard';
// import NotFound from 'views/layouts/NotFound';

export const allRoutes = [
  {
    path: '/dashboard',
    element: <DashboardView />,
    displayName: 'Quiz Dashboard',
    icon: null,
  },
  {
    path: '/recommendations',
    element: <RecommendationsView />,
    displayName: 'Shade Recommendations',
    icon: null,
    children: [
      {
        path: `/recommendations/level/:level`,
        element: <RecommendationsView />,
      },
    ],
  },
  {
    path: '/rules',
    displayName: 'Target Level Logic',
    icon: null,
    children: [
      {
        path: '/rules',
        element: <RulesView />,
        displayName: 'Rule Set',
        icon: null,
      },
      {
        path: '/rules/scenarios',
        element: <ScenariosView />,
        displayName: 'Scenarios',
        icon: null,
      },
    ],
  },
  {
    path: '/shades',
    displayName: 'Shade Configuration',
    icon: null,
    children: [
      {
        path: '/shades',
        element: <ShadesView />,
        displayName: 'Shade Data',
        icon: null,
      },
      {
        path: '/shades/partners',
        element: <PartnersView />,
        displayName: 'Partner Products',
        icon: null,
      },
    ],
  },
];

const routes = [
  {
    path: '/',
    exact: true,
    element: <Page />,
    children: allRoutes,
  },
];

export default routes;
