import React from 'react';
import { NavLink as Link, matchPath, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, ListItem } from '@mui/material';

// TODO: fix styling
const NavItem = ({ href, title, ...rest }) => {
  const location = useLocation();

  const active = href
    ? !!matchPath({ path: `/${href}` }, location.pathname + location.search)
    : false;

  return (
    <ListItem
      disableGutters
      sx={{
        display: 'flex',
        py: 0,
        ...(active && {
          color: 'primary.main',
          borderLeft: '2px solid',
          backgroundColor: 'rgba(83,40,79,0.04)',
        }),
      }}
      {...rest}
    >
      <Button
        component={Link}
        sx={{
          color: 'text.secondary',
          fontWeight: 'medium',
          justifyContent: 'flex-start',
          letterSpacing: 0,
          py: 1.25,
          textTransform: 'none',
          width: '100%',
          ...(active && {
            color: 'primary.main',
          }),
          '& svg': {
            mr: 1,
          },
        }}
        to={href}
      >
        <span>{title}</span>
      </Button>
    </ListItem>
  );
};

NavItem.propTypes = {
  href: PropTypes.string,
  title: PropTypes.string,
};

export default NavItem;
