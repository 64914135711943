import React from 'react';
import PropTypes from 'prop-types';

import { Box, Stack, Divider } from '@mui/material';

import Filter from './Filter';

const FilterBar = ({
  filterProperties,
  onFilter,
  selectedFilters = [],
  divider = false,
  direction = 'row',
  align = 'flex-start',
  justify = 'flex-start',
}) => {
  return (
    <Box>
      <Stack
        direction={direction}
        divider={divider ? <Divider orientation="vertical" flexItem /> : false}
        spacing={4}
        alignItems={align}
        justifyContent={justify}
      >
        {filterProperties.length > 0 &&
          filterProperties.map(({ formattedKey, key, items, multiple, clearable, fullWidth }) => {
            let selectedValue = selectedFilters[key];
            if (multiple) {
              selectedValue = Array.isArray(selectedValue)
                ? selectedValue
                : selectedValue
                ? [selectedValue]
                : [];
            }
            return (
              <Filter
                key={key}
                formattedKey={formattedKey}
                filterKey={key}
                items={items}
                selectedValue={selectedValue}
                onChange={onFilter}
                multiple={multiple}
                clearable={clearable}
                fullWidth={fullWidth}
              />
            );
          })}
      </Stack>
    </Box>
  );
};

FilterBar.propTypes = {
  filterProperties: PropTypes.array,
  onFilter: PropTypes.func,
  selectedFilters: PropTypes.object,
  divider: PropTypes.bool,
  multiple: PropTypes.bool,
  align: PropTypes.string,
  direction: PropTypes.string,
  justify: PropTypes.string,
};

export default FilterBar;
