import { createActions } from 'redux-actions';

const actions = createActions({
  APP: {
    UI: {
      SNACKBAR: {
        SET: data => data,
        RESET: undefined,
      },
      NOTIFICATION: {
        SET: data => data,
        RESET: undefined,
      },
      LOADING: {
        ON: undefined,
        OFF: undefined,
      },
      BATCH: {
        TOTAL: {
          SET: total => total,
        },

        ITEMS: {
          SET: items => items,
          SHIFT: undefined,
        },

        INCREMENT: undefined,

        RETRY: {
          TOGGLE: bool => bool,
        },

        RESET: undefined,
      },
    },
    AGENT: {
      SET: data => data,
    },
    PRODUCTS: {
      SET: data => data,

      DATA: {
        SET: data => data,
      },
    },
  },
});

export default actions.app;
