import { createActions } from 'redux-actions';

const actions = createActions({
  RECOMMENDATION: {
    DATA: {
      SET: data => data,
    },
  },
});

export default actions.recommendation;
