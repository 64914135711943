import { combineReducers } from 'redux-immutable';
import { connectRouter } from 'connected-react-router/immutable';

import ruleSet from './ruleSet';
import recommendationSet from './recommendationSet';
import recommendation from './recommendation';
import app from './app';
import quiz from './quiz';
import shade from './shade';

const createRootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    app,
    ruleSet,
    recommendationSet,
    quiz,
    recommendation,
    shade,
  });

export default createRootReducer;
