import { handleActions } from 'redux-actions';
import { fromJS } from 'immutable';

export const defaultState = fromJS({
  data: [],
  scenario: {
    facts: [],
    output: {},
  },
});

export default handleActions(
  {
    RULESET: {
      DATA: {
        SET: (state, { payload }) => state.set('data', fromJS(payload)),
        UPDATE: (state, { payload }) => {
          const idx = state.get('data').findIndex(ruleSet => ruleSet.get('id') === payload.id);
          return state.setIn(['data', idx], fromJS(payload));
        },
      },
      SCENARIO: {
        SET: (state, { payload }) => state.set('scenario', fromJS(payload)),

        FACTS: {
          SET: (state, { payload }) => state.setIn(['scenario', 'facts'], fromJS(payload)),
        },

        OUTPUT: {
          SET: (state, { payload }) => state.setIn(['scenario', 'output'], fromJS(payload)),
        },
      },
    },
  },
  defaultState
);
