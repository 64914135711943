import React from 'react';
import PropTypes from 'prop-types';

import { MenuItem, Checkbox, ListItemText, Box, Chip, IconButton, TextField } from '@mui/material';

import CancelIcon from '@mui/icons-material/Cancel';

import { removeUnderscores } from 'utils/stringUtils';

const Filter = ({
  filterKey,
  formattedKey = filterKey,
  selectedValue,
  onChange,
  items,
  multiple = false,
  fullWidth = false,
  clearable = false,
}) => {
  const onDelete = value => {
    selectedValue = selectedValue.filter(sel => sel !== value);
    onChange({ target: { name: filterKey, value: selectedValue } });
  };
  return (
    items.length !== 0 && (
      <TextField
        fullWidth={fullWidth}
        sx={{
          mb: 2,
          minWidth: '200px',
          maxWidth: !fullWidth && '200px',
          backgroundColor: '#FFFFFF',
        }}
        className="filter-control"
        size="small"
        name={filterKey}
        label={formattedKey}
        variant="outlined"
        select
        InputProps={{
          endAdornment: (clearable || multiple) && selectedValue.length !== 0 && (
            <IconButton
              onClick={() => onChange({ target: { name: filterKey, value: '' } })}
              edge="start"
              size="small"
              sx={{ color: 'lightgrey', mr: 1 }}
            >
              <CancelIcon fontSize="small" />
            </IconButton>
          ),
        }}
        SelectProps={{
          onChange: onChange,
          value: selectedValue,
          multiple: multiple,
          renderValue: selected => {
            if (selected) {
              if (multiple) {
                return (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map(value => (
                      <Chip
                        key={value}
                        label={removeUnderscores(items.find(i => value === i.value).label)}
                        size="small"
                        onDelete={() => onDelete(value)}
                        onMouseDown={e => e.stopPropagation()}
                      />
                    ))}
                  </Box>
                );
              }
              return removeUnderscores(items.find(i => selected === i.value).label);
            }
          },
        }}
      >
        {items.map(({ label, value }) => (
          <MenuItem value={value} key={value} sx={{ textTransform: 'capitalize' }}>
            {multiple && <Checkbox checked={selectedValue.includes(value)} />}
            <ListItemText primary={label} />
          </MenuItem>
        ))}
      </TextField>
    )
  );
};

Filter.propTypes = {
  formattedKey: PropTypes.string,
  filterKey: PropTypes.string,
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.number]),
  onChange: PropTypes.func,
  items: PropTypes.array,
  multiple: PropTypes.bool,
  fullWidth: PropTypes.bool,
  clearable: PropTypes.bool,
};

export default Filter;
