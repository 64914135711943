import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { getFactsFromQuiz } from 'state/redux/quiz/selectors';

import RuleDetailsView from '../components/RuleDetails';
import RuleInput from '../components/RuleInput';
import RuleOutput from '../components/RuleOutput';

import { DEFAULT_QUIZ, OPERATORS, TARGET_LEVEL, ACTION_TYPES } from 'utils/constants';

const validate = () => {
  const errors = {};
  // TODO: validate lol
  return errors;
};

const opSelect = Object.keys(OPERATORS).map(key => ({
  value: key,
  label: OPERATORS[key].label,
  type: OPERATORS[key].type,
}));

const outputSelect = Object.keys(TARGET_LEVEL).map(key => ({
  label: TARGET_LEVEL[key].label,
  key,
}));

const actionSelect = Object.keys(ACTION_TYPES).map(key => ({
  label: ACTION_TYPES[key].label,
  key,
}));

const RuleDetails = ({ rule, saveRule, unsavedRule }) => {
  const facts = useSelector(state => getFactsFromQuiz(state, DEFAULT_QUIZ.quizKey));

  const factSelect = [...new Set(facts.map(fact => fact.key))];

  const answerSelect = key => facts.find(fact => fact.key === key)?.answers;

  return rule ? (
    <RuleDetailsView
      rule={rule}
      onSubmit={saveRule}
      validate={validate}
      unsavedRule={unsavedRule}
      renderInput={(input, push) => (
        <RuleInput
          fields={input}
          factSelect={factSelect}
          answerSelect={answerSelect}
          opSelect={opSelect}
          push={push}
        />
      )}
      renderOutput={(input, push) => (
        <RuleOutput
          fields={input}
          factSelect={factSelect}
          opSelect={opSelect}
          outputSelect={outputSelect}
          actionSelect={actionSelect}
          push={push}
        />
      )}
    />
  ) : null;
};

RuleDetails.propTypes = {
  rule: PropTypes.object,
  saveRule: PropTypes.func,
  unsavedRule: PropTypes.func,
};

export default RuleDetails;
