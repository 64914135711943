import { handleActions } from 'redux-actions';
import { fromJS } from 'immutable';

import { PRODUCTS } from 'utils/constants';

let productState = {};

Object.keys(PRODUCTS).forEach(key => {
  const productCode = PRODUCTS[key].code;
  productState[productCode] = [];
});

export const defaultState = fromJS({
  ui: {
    isLoading: true,
    snackbar: {
      open: false,
      message: '',
      type: '',
    },
    notification: {
      open: false,
      message: '',
      title: '',
    },
    batch: {
      items: [],
      total: 0,
      counter: 0,
      retry: false,
    },
  },
  agent: {},
  products: {
    data: [],
    ...productState,
  },
});

export default handleActions(
  {
    APP: {
      UI: {
        SNACKBAR: {
          SET: (state, { payload }) => state.setIn(['ui', 'snackbar'], payload),
          RESET: state => state.setIn(['ui', 'snackbar'], defaultState.getIn(['ui', 'snackbar'])),
        },
        NOTIFICATION: {
          SET: (state, { payload }) => state.setIn(['ui', 'notification'], payload),
          RESET: state =>
            state.setIn(['ui', 'notification'], defaultState.getIn(['ui', 'notification'])),
        },
        LOADING: {
          ON: state => state.setIn(['ui', 'isLoading'], true),
          OFF: state => state.setIn(['ui', 'isLoading'], false),
        },
        BATCH: {
          TOTAL: {
            SET: (state, { payload: total }) => state.setIn(['ui', 'batch', 'total'], total),
          },

          ITEMS: {
            SET: (state, { payload: items }) =>
              state.setIn(['ui', 'batch', 'items'], fromJS(items)),
            SHIFT: state =>
              state.setIn(['ui', 'batch', 'items'], state.getIn(['ui', 'batch', 'items']).shift()),
          },

          INCREMENT: state =>
            state.setIn(['ui', 'batch', 'counter'], state.getIn(['ui', 'batch', 'counter']) + 1),

          RETRY: {
            TOGGLE: (state, { payload: retry }) => state.setIn(['ui', 'batch', 'retry'], retry),
          },

          RESET: state => state.setIn(['ui', 'batch'], defaultState.getIn(['ui', 'batch'])),
        },
      },
      AGENT: {
        SET: (state, { payload }) => state.set('agent', fromJS(payload)),
      },
      PRODUCTS: {
        SET: (state, { payload }) => state.setIn(['products', payload.key], fromJS(payload.data)),

        DATA: {
          SET: (state, { payload }) => state.setIn(['products', 'data'], fromJS(payload)),
        },
      },
    },
  },
  defaultState
);
