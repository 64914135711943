import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { store, history } from './state/configureStore';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { fetchTophatData } from 'state/redux/app/operations';

store.dispatch(fetchTophatData());

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <DndProvider backend={HTML5Backend}>
        <App />
      </DndProvider>
    </Router>
  </Provider>,
  document.querySelector('#root')
);
