import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation, Navigate, useParams } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import Header from 'views/common/Navigation/Header';
import Sidebar from 'views/common/Navigation/Sidebar';
import Snackbar from 'views/common/SnackBar';
import AlertDialog from 'views/common/AlertDialog';

import RecommendationsView from 'views/layouts/Recommendations';
import { allRoutes } from '../../routes';

import { getLevelRange } from 'state/redux/recommendationSet/selectors';
import { getIsLoading, getIsLoggedIn } from 'state/redux/app/selectors';
import { PRODUCTS } from 'utils/constants';
import useQuery from 'hooks/useQuery';

const PageRoot = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  height: '100%',
  overflow: 'hidden',
  width: '100%',
}));

const PageWrapper = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
  paddingTop: 64,
});

const PageContainer = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
});

const PageContent = styled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto',
});

const Page = () => {
  const location = useLocation();
  const query = useQuery();

  const { level } = useParams();
  const recSetId = parseInt(query.get('recSetId'));

  let redirect = false;
  let redirectUrl = '';
  if (
    (location.pathname.includes('recommendations') && (!level || !recSetId)) ||
    location.pathname === '/'
  ) {
    redirect = true;
    redirectUrl = '/recommendations/level/2?recSetId=1';
  }

  const levels = useSelector(state => getLevelRange(state, PRODUCTS.RCC.code));
  const isLoading = useSelector(state => getIsLoading(state));
  const isLoggedIn = useSelector(state => getIsLoggedIn(state));

  let children = [];
  const route = allRoutes.find(route => location.pathname.includes(route.path));
  if (route && route.children) {
    children = route.children;
  }

  if (location.pathname.includes('recommendations')) {
    children = levels.map(l => ({
      path: `/recommendations/level/${l}${location.search}`,
      element: <RecommendationsView />,
      displayName: `Level ${l}`,
    }));
  }

  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  return redirect ? (
    <Navigate replace to={redirectUrl} />
  ) : (
    <PageRoot>
      <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar />
      <AlertDialog />
      <Header onMobileNavOpen={() => setMobileNavOpen(true)} isMobileNavOpen={isMobileNavOpen} />
      {isLoggedIn ? (
        <>
          {children.length > 0 ? (
            <Sidebar
              onMobileClose={() => setMobileNavOpen(false)}
              openMobile={isMobileNavOpen}
              subNav={children}
            />
          ) : null}
          <PageWrapper
            sx={
              children.length > 0
                ? {
                    paddingLeft: {
                      lg: '256px',
                    },
                  }
                : ''
            }
          >
            <PageContainer>
              <PageContent>
                <Outlet />
              </PageContent>
            </PageContainer>
          </PageWrapper>
        </>
      ) : null}
    </PageRoot>
  );
};

export default Page;
