import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getQuestionsData } from 'state/redux/quiz/selectors';
import { getRuleSetFacts, getRuleSetByID, getRuleOutput } from 'state/redux/ruleSet/selectors';
import { fetchRuleSets, runScenario } from 'state/redux/ruleSet/operations';

import ScenariosView from '../components/Scenarios';

import { DEFAULT_RULESET_ID } from 'utils/constants';

const Scenarios = () => {
  const dispatch = useDispatch();
  const { ruleSetId } = useParams();

  const ruleSet = useSelector(state => getRuleSetByID(state, ruleSetId || DEFAULT_RULESET_ID));

  const factKeys = useSelector(state => getRuleSetFacts(state));

  const output = useSelector(state => getRuleOutput(state));

  useEffect(() => {
    dispatch(fetchRuleSets());
  }, [factKeys.size, dispatch]);

  const questions = useSelector(state => getQuestionsData(state)).toJS();

  const facts = factKeys?.map(key => questions?.find(q => q.key === key));

  const factSelect = [...new Set(facts?.map(fact => fact?.key))];

  const answerSelect = key => facts?.find(fact => fact?.key === key)?.answers;

  const checkAnswers = answers => {
    dispatch(runScenario(ruleSet.id, answers));
  };

  return (
    <ScenariosView
      factSelect={factSelect}
      answerSelect={answerSelect}
      onSubmit={checkAnswers}
      results={output}
    />
  );
};

export default Scenarios;
