import React, { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useDrop } from 'react-dnd';

import { fetchRuleSets, updateRuleSet } from 'state/redux/ruleSet/operations';
import { getRuleSetByID, getRuleSetData } from 'state/redux/ruleSet/selectors';
import uiActions from 'state/redux/app/actions';

import RulesView from '../components/Rules';
import Rule from '../components/Rule';

import { DEFAULT_RULESET_ID } from 'utils/constants';

const Rules = () => {
  const dispatch = useDispatch();

  const { ruleSetId } = useParams();
  const ruleSets = useSelector(state => getRuleSetData(state));

  useEffect(() => {
    if (ruleSets.size === 0) dispatch(fetchRuleSets());
    // eslint-disable-next-line
  }, [dispatch]);

  const ruleSet = useSelector(state => getRuleSetByID(state, ruleSetId || DEFAULT_RULESET_ID));

  const [rules, setRules] = useState(ruleSet.rules);
  const [selectedId, setSelectedId] = useState(null);
  const [unsavedEdits, setUnsavedEdits] = useState([]);
  const [unsavedRule, setUnsavedRule] = useState(false);

  useEffect(() => {
    setRules(ruleSet.rules);
    // only reset rules on render if ruleSet ID changes
    // eslint-disable-next-line
  }, [ruleSet.id]);

  const discardAllChanges = () => {
    setUnsavedEdits([]);
    setRules(ruleSet.rules);
  };

  const findRule = useCallback(
    id => {
      const rule = rules.find(r => r.id === id);
      return {
        rule,
        index: rules.indexOf(rule),
      };
    },
    [rules]
  );

  const selectRule = id => {
    if (unsavedRule) {
      const handleClick = value => {
        if (value) setSelectedId(id);
        return;
      };
      dispatch(
        uiActions.ui.notification.set({
          message:
            "There are unsaved changes to the current rule. Don't worry, rule changes will not save to the ruleset until you select 'Save to RuleSet'.",
          title: 'Unsaved Changes',
          open: true,
          onDone: handleClick,
        })
      );
    } else {
      setSelectedId(id);
    }
  };

  const moveRule = useCallback(
    (id, atIndex) => {
      const { rule, index } = findRule(id);
      let newRules = rules.slice();
      newRules.splice(index, 1);
      newRules.splice(atIndex, 0, rule);
      setRules(newRules);
      setUnsavedEdits(unsavedEdits => unsavedEdits.concat(id));
    },
    [findRule, rules, setRules]
  );

  const addRule = () => {
    const id = Math.max(...rules.map(r => r.id)) + 1;
    const newRules = [...rules, { name: 'New Rule', description: null, id }];
    setRules(newRules);
    selectRule(id);
    setUnsavedEdits(unsavedEdits => unsavedEdits.concat(id));
  };

  const deleteRule = (e, id) => {
    const { index } = findRule(id);
    let newRules = rules.slice();
    newRules.splice(index, 1);
    setRules(newRules);
    if (selectedId === id) {
      setSelectedId(null);
    }
    setUnsavedEdits(unsavedEdits => unsavedEdits.concat(id));
    e.stopPropagation();
  };

  const saveRule = async values => {
    values.actions.forEach(action => {
      if (action.operator !== 'SET_COMPARE') {
        action.formula = [];
      }
    });
    const newRules = rules.map(rule => (rule.id === values.id ? { ...rule, ...values } : rule));
    setRules(newRules);
    setUnsavedEdits(unsavedEdits => unsavedEdits.concat(values.id));
  };

  const saveRuleSet = async () => {
    const error = dispatch(updateRuleSet(ruleSet.id, rules));
    if (!error) {
      setUnsavedEdits([]);
    }
  };

  const [, drop] = useDrop(() => ({ accept: 'rule' }));

  return ruleSet ? (
    <RulesView
      ruleSet={ruleSet}
      rules={rules}
      drop={drop}
      addRule={addRule}
      saveRule={saveRule}
      selectedId={selectedId}
      hasUnsavedEdits={unsavedEdits.length > 0}
      discardAllChanges={discardAllChanges}
      saveRuleSet={saveRuleSet}
      unsavedRule={setUnsavedRule}
      renderRules={(rule, i) => (
        <Rule
          key={rule.id}
          index={i}
          rule={rule}
          edited={unsavedEdits.includes(rule.id)}
          moveRule={moveRule}
          deleteRule={deleteRule}
          findRule={findRule}
          selectedId={selectedId}
          selectRule={selectRule}
        />
      )}
    />
  ) : null;
};

export default Rules;
