export const PRODUCTS = {
  RCC: {
    id: 159,
    name: 'Radiant Cream Color',
    code: 'color_kit',
  },
  LW: {
    id: 217,
    name: 'Light Works',
    code: 'light_works',
  },
  GLOSS: {
    id: 182,
    name: 'Gloss',
    code: 'gloss',
  },
  RRB: {
    id: 201,
    name: 'Root Reboot',
    code: 'root_reboot',
  },
  TGCU: {
    id: 303,
    name: 'Root Touch Up Powder',
    code: 'root_touch_up',
  },
  COLOR_WONDER: {
    name: 'Color Wonder',
    code: 'colorwonder',
  },
};

export const TONES = {
  ASH: 'ash',
  NATURAL: 'natural',
  GOLD: 'gold',
  COPPER: 'copper',
  MAHOGANY: 'mahogany',
  RED: 'red',
  BLUE_BLACK: 'blue_black',
  SILVER: 'silver',
  BEIGE: 'beige',
  ROSE_GOLD: 'rose_gold',
  BURGUNDY: 'burgundy',
  PLUM: 'plum',
  PINK_VIOLET: 'pink_violet',
};

export const DEFAULT_RULESET_ID = 1;
export const DEFAULT_QUIZ = {
  id: 27,
  quizKey: 'advisor_v2',
};

export const OPERATORS = {
  EQ: {
    label: 'Equals',
    type: 'INPUT',
  },
  NOTEQ: {
    label: 'Does not equal',
    type: 'INPUT',
  },
  LT: {
    label: 'Is less than',
    type: 'INPUT',
  },
  GT: {
    label: 'Is greater than',
    type: 'INPUT',
  },
  LTEQ: {
    label: 'Is less than or equal to',
    type: 'INPUT',
  },
  GTEQ: {
    label: 'Is greater than or equal to',
    type: 'INPUT',
  },
  SET: {
    label: 'Is set',
    type: 'INPUT',
  },
  NOTSET: {
    label: 'Is not set',
    type: 'INPUT',
  },
  DARKER: {
    label: 'Is darker',
    type: 'COMPARE',
  },
  LIGHTER: {
    label: 'Is lighter',
    type: 'COMPARE',
  },
  ADD: {
    label: 'Add',
    type: 'OUTPUT',
  },
  SUB: {
    label: 'Subtract',
    type: 'OUTPUT',
  },
};

export const ACTION_TYPES = {
  SET_FACT: {
    label: 'Set to fact (input/output)',
  },
  SET_FORMULA: {
    label: 'Set to formula',
  },
  SET_STATIC: {
    label: 'Set to static value',
  },
  SET_COMPARE: {
    label: 'Compare',
  },
};

export const TARGET_LEVEL = {
  MIN: {
    value: null,
    label: 'Minimum',
  },
  MAX: {
    value: null,
    label: 'Maximum',
  },
  LEVEL: {
    value: null,
    label: 'Level',
  },
};
