import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const MRButton = styled(Button)({
  textTransform: 'initial',
});

const SaveBarButton = ({
  variant = 'contained',
  color = 'secondary',
  onClick = () => {},
  text = 'Save',
}) => {
  return (
    <MRButton variant={variant} color={color} onClick={onClick} size="large">
      {text}
    </MRButton>
  );
};

SaveBarButton.propTypes = {
  variant: PropTypes.string,
  color: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
};

export default SaveBarButton;
