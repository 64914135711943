import React from 'react';
import PropTypes from 'prop-types';

import { Grid, Typography, Box } from '@mui/material';

import { styled } from '@mui/styles';

const RuleLog = styled(Typography)({
  paddingLeft: '20px',
  lineHeight: '2em',
});

const Code = styled('code')({
  borderRadius: '0.3em',
  backgroundColor: '#FFFFFF',
  padding: '4px 5px 6px',
  lineHeight: '2em',
});

const Pass = styled('span')({
  color: 'green',
});

const Fail = styled('span')({
  color: 'red',
});

const ScenarioOutput = ({ output }) => {
  return (
    <Grid item xs={12}>
      <Typography variant="h6" component="div">
        <strong>RULE {output.id}</strong>:{' '}
        {output.fired ? <Pass>FIRED</Pass> : <Fail>NOT FIRED</Fail>}
      </Typography>
      <RuleLog variant="p" component="div">
        {output.constraints.map((c, i) => {
          return (
            <Box key={i}>
              <strong>CONSTRAINT</strong>:{` `}
              <Code>{c.fact}</Code> ({c.answer.toString()}) {c.operator}
              {` `}
              {!c.operator.includes('SET') ? c.value.toString() : ''}
              {` `}({c.pass ? <Pass>pass</Pass> : <Fail>fail</Fail>})
            </Box>
          );
        })}
      </RuleLog>
      <RuleLog variant="p" component="div">
        {output.actions.length !== 0 ? (
          output.actions.map((a, i) => {
            return (
              <Box key={i}>
                <strong>ACTION</strong>: OUTPUT:{` `}
                <strong>
                  <Code>{a.output}</Code>
                </strong>{' '}
                <strong>{a.result}</strong> |{` `}
                {a.action === 'SET_STATIC' ? (
                  <>{a.value.toString()}</>
                ) : a.action === 'SET_FACT' ? (
                  <>
                    <Code>{a.fact}</Code> ({a.answer.toString()})
                  </>
                ) : a.action === 'SET_FORMULA' ? (
                  <>
                    <Code>{a.fact}</Code> ({a.answer.toString()}) {a.operator} {a.value.toString()}
                  </>
                ) : a.action === 'SET_COMPARE' ? (
                  <>
                    `COMPARE `
                    {a.formula[0].action === 'SET_STATIC' ? (
                      a.formula[0].value.toString()
                    ) : a.formula[0].action === 'SET_FACT' ? (
                      <>
                        <Code>{a.formula[0].fact}</Code> ({a.formula[0].answer.toString()})
                      </>
                    ) : a.formula[0].action === 'SET_FORMULA' ? (
                      <>
                        <Code>{a.formula[0].fact}</Code> ({a.formula[0].answer.toString()}){' '}
                        {a.formula[0].operator} {a.formula[0].value.toString()}
                      </>
                    ) : null}
                    {` `}
                    {a.operator}
                    {` `}
                    {a.formula[1].action === 'SET_STATIC' ? (
                      a.formula[1].value.toString()
                    ) : a.formula[1].action === 'SET_FACT' ? (
                      <>
                        <Code>{a.formula[1].fact}</Code> ({a.formula[1].answer.toString()})
                      </>
                    ) : a.formula[1].action === 'SET_FORMULA' ? (
                      <>
                        <Code>{a.formula[1].fact}</Code> ({a.formula[1].answer.toString()}){' '}
                        {a.formula[1].operator} {a.formula[1].value.toString()}
                      </>
                    ) : null}
                  </>
                ) : null}
              </Box>
            );
          })
        ) : (
          <Box>
            <strong>NO OUTPUT</strong>
          </Box>
        )}
      </RuleLog>
    </Grid>
  );
};

ScenarioOutput.propTypes = {
  output: PropTypes.object,
};

export default ScenarioOutput;
