import React from 'react';
import PropTypes from 'prop-types';

import { Box, Container, Divider } from '@mui/material';
import { DataGrid, GridOverlay } from '@mui/x-data-grid';

import FilterBar from 'views/common/FilterBar';
import SaveBar from 'views/common/SaveBar';

// TODO: fix styling
const RecommendationsView = ({
  columns,
  rows,
  filterProperties,
  selectedFilters,
  onFilter,
  onEdit,
  onSave,
  unsavedEdits,
  onCancel,
  channelFilter,
  selectChannel,
  selectedChannel,
}) => {
  const buttons = [
    {
      text: 'Discard',
      onClick: onCancel,
      variant: 'outlined',
      color: 'dark',
    },
    {
      text: 'Save',
      onClick: onSave,
      variant: 'contained',
      color: 'secondary',
    },
  ];
  return (
    <Container maxWidth="xl">
      <Box sx={{ pt: 3 }}>
        <FilterBar
          filterProperties={channelFilter}
          onFilter={selectChannel}
          justify="flex-end"
          x
          selectedFilters={selectedChannel}
        />
        <Divider light sx={{ mb: 2 }} />
        <FilterBar
          filterProperties={filterProperties}
          selectedFilters={selectedFilters}
          onFilter={onFilter}
        />
        <div style={{ height: 400, width: '100%' }}>
          <div style={{ display: 'flex', height: '100%' }}>
            <div style={{ flexGrow: 1, marginBottom: '30px' }}>
              <DataGrid
                className="root"
                autoHeight
                disableVirtualization
                disableColumnFilter
                hideFooter={true}
                columns={columns}
                rows={rows}
                onCellEditCommit={onEdit}
                components={{
                  NoRowsOverlay: () => <GridOverlay>No recommendations found.</GridOverlay>,
                }}
              />
              <Box sx={{ height: '56px' }} />
            </div>
          </div>
        </div>
      </Box>
      {unsavedEdits > 0 ? <SaveBar buttons={buttons} unsavedEdits={unsavedEdits} /> : null}
    </Container>
  );
};

RecommendationsView.propTypes = {
  columns: PropTypes.array,
  rows: PropTypes.array,
  filterProperties: PropTypes.array,
  selectedFilters: PropTypes.object,
  onFilter: PropTypes.func,
  onEdit: PropTypes.func,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  unsavedEdits: PropTypes.number,
  channelFilter: PropTypes.array,
  selectChannel: PropTypes.func,
  selectedChannel: PropTypes.object,
};

export default RecommendationsView;
