import { createActions } from 'redux-actions';

const actions = createActions({
  QUIZ: {
    QUIZZES: {
      SET: data => data,
    },
    QUESTIONS: {
      SET: data => data,
    },
  },
});

export default actions.quiz;
