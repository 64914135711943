import { GET, POST, DELETE, PATCH } from './makeAPI';

const apiUrl = process.env.REACT_APP_API_URL;
const advisor = [
  {
    host: apiUrl,
    url: '/advisor/ruleSet',
    methods: [GET, POST],
  },
  {
    host: apiUrl,
    url: '/advisor/ruleSet/:id',
    methods: [GET, PATCH, DELETE],
  },
  {
    host: apiUrl,
    url: '/advisor/recommendationSet',
    methods: [GET, POST],
  },
  {
    host: apiUrl,
    url: '/advisor/recommendationSet/:id',
    methods: [GET, PATCH, DELETE],
  },
  {
    host: apiUrl,
    url: '/advisor/recommendation',
    methods: [PATCH, GET],
  },
  {
    host: apiUrl,
    url: '/advisor/recommendation/:id',
    methods: [GET, POST],
  },
  {
    host: apiUrl,
    url: '/advisor/shade',
    methods: [PATCH, GET, POST],
  },
  {
    host: apiUrl,
    url: '/advisor/shade/:id',
    methods: [PATCH, GET, POST],
  },
  {
    host: apiUrl,
    url: '/advisor/rulesEngine/level/:id',
    methods: [POST],
  },
  {
    host: apiUrl,
    url: '/advisor/rulesEngine/tones/:id',
    methods: [POST],
  },
  {
    host: apiUrl,
    url: '/advisor/rulesEngine/recommendations/:id',
    methods: [POST],
  },
  {
    host: apiUrl,
    url: '/advisor/rulesEngine/clearCache',
    methods: [POST],
  },
];

const tophatUrl = process.env.REACT_APP_TOPHAT_URL;
const tophat = [
  {
    host: tophatUrl,
    url: '/tophat/rest/quizBuilder/getAllQuiz',
    methods: [GET],
  },
  {
    host: tophatUrl,
    url: '/tophat/rest/quizBuilder/getAllQuestion',
    methods: [GET],
  },
  {
    host: tophatUrl,
    url: '/tophat/rest/quizBuilder/getQuestionById',
    methods: [GET],
  },
  {
    host: tophatUrl,
    url: '/tophat/rest/agent/getLoggedInAgent',
    methods: [GET],
  },
  {
    host: tophatUrl,
    url: '/tophat/rest/colorAdvisorAdmin/getDataForEditingComplexProduct',
    methods: [GET],
  },
  {
    host: tophatUrl,
    url: '/tophat/rest/products/getAllWithExtraData',
    methods: [GET],
  },
  {
    host: tophatUrl,
    url: '/tophat/rest/partnerProducts/getProductsForPartner',
    methods: [GET],
  },
];

export default [...advisor, ...tophat];
