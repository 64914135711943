import { createActions } from 'redux-actions';

const actions = createActions({
  SHADE: {
    DATA: {
      SET: data => data,
      UPDATE: data => data,
    },
  },
});

export default actions.shade;
