import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';

import RecommendationsView from '../components/Recommendations';

import {
  fetchRecommendationsByLevel,
  saveRecommendations,
  cleanRecommendations,
} from 'state/redux/recommendation/operations';
import { fetchRecommendationSets } from 'state/redux/recommendationSet/operations';
import { getRecommendationData } from 'state/redux/recommendation/selectors';
import {
  getRecommendationSetByID,
  getRecommendationSetData,
} from 'state/redux/recommendationSet/selectors';
import { getQuizQuestions } from 'state/redux/quiz/selectors';
import { getIsLoading } from 'state/redux/app/selectors';
import { getShadeData } from 'state/redux/shade/selectors';
import { fetchShades } from 'state/redux/shade/operations';

import { TONES } from 'utils/constants';
import { removeUnderscores, formatCellValue } from 'utils/stringUtils';
import useQuery from 'hooks/useQuery';
import useFilteredDataGrid from 'hooks/useFilteredDataGrid';

const Recommendations = () => {
  const query = useQuery();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isLoading = useSelector(state => getIsLoading(state));

  const { level } = useParams();
  const recSetId = parseInt(query.get('recSetId'));

  const allRecs = useSelector(state => getRecommendationData(state));
  const allChannels = useSelector(state => getRecommendationSetData(state)).toJS();
  const recSet = useSelector(state => getRecommendationSetByID(state, recSetId));
  const quizQuestions = useSelector(state =>
    getQuizQuestions(state, recSet.quizKeys && recSet.quizKeys[0])
  );
  const shades = useSelector(state => getShadeData(state));

  useEffect(() => {
    dispatch(fetchRecommendationSets());
    dispatch(fetchShades());
  }, [allRecs.length, dispatch]);

  useEffect(() => {
    dispatch(fetchRecommendationsByLevel(recSetId, parseInt(level)));
  }, [dispatch, recSetId, level]);

  useEffect(() => {
    return () => {
      dispatch(cleanRecommendations());
    };
  }, [dispatch]);

  const data = useMemo(() => {
    if (allRecs && allRecs.length) {
      return allRecs.map(row => {
        const obj = {
          level: row.level,
          id: row.id,
          ...row.tones,
        };
        row.facts.forEach(f => {
          obj[f.key] = f.value;
        });
        return obj;
      });
    }
    return [];
  }, [allRecs]);

  const shadeCodes = [
    { value: '', label: '--' },
    ...shades
      .filter(
        shade =>
          parseInt(shade.level) === parseInt(level) ||
          (parseInt(shade.level) == 11 && parseInt(level) == 10) // Explicitly allow level 11 to show on level 10 dropdowns
      )
      .filter(shade => (recSet.partner ? shade.partners.length !== 0 : shade))
      .filter(shade => shade.showInAdvisor)
      .map(shade => shade.code),
  ];

  const toneColumns = Object.keys(TONES).map(key => ({
    field: TONES[key],
    valueFormatter: p => (p.value ? p.value : '--'),
    headerName: removeUnderscores(TONES[key]),
    type: 'singleSelect',
    valueOptions: shadeCodes,
    editable: true,
  }));

  toneColumns[0] = {
    ...toneColumns[0],
    cellClassName: 'left-divider',
    headerClassName: 'left-divider',
  };

  const facts = recSet.facts || [];

  const factColumns =
    facts.map(fact => {
      return {
        field: fact,
        headerName: removeUnderscores(fact),
        valueFormatter: p => formatCellValue(p.value),
      };
    }) || [];

  const columns = [
    {
      field: 'level',
      headerName: 'Target Level',
      valueFormatter: p => 'Level ' + p.value,
    },
  ]
    .concat(factColumns)
    .concat(toneColumns);

  // formatting data for filters based on recommendation set facts
  const factData = quizQuestions.filter(q => facts.includes(q.key));

  const filterProperties = factData.map(fact => {
    const formattedAnswers = fact.answers.map(({ text, value }) => ({
      label: fact.dataType === 'boolean' ? text : removeUnderscores(value),
      value: value,
    }));

    return {
      formattedKey: removeUnderscores(fact.key),
      key: fact.key,
      items: formattedAnswers,
      multiple: true,
    };
  });

  const {
    rows,
    setFilteredData,
    updateData,
    onFilterApply,
    filters,
    commitEdit,
    editCount,
    handleSave,
    handleCancel,
  } = useFilteredDataGrid(data, facts);

  useEffect(() => {
    setFilteredData(data);
    updateData();
    // eslint-disable-next-line
  }, [updateData, data, level]);

  const [channel, setChannel] = useState({ channel: recSetId });

  const channelFilter = [
    {
      formattedKey: 'Channel',
      key: 'channel',
      items: allChannels.map(rec => ({
        label: rec.name,
        value: rec.id,
      })),
    },
  ];

  const selectChannel = ({ target }) => {
    query.set('recSetId', target.value);
    navigate({ search: query.toString() });
    setChannel({ [target.name]: target.value });
  };

  return (
    !isLoading && (
      <RecommendationsView
        columns={columns}
        rows={rows}
        filterProperties={filterProperties}
        selectedFilters={filters}
        onFilter={onFilterApply}
        onEdit={commitEdit}
        onSave={() => handleSave(saveRecommendations)}
        unsavedEdits={editCount}
        onCancel={handleCancel}
        channelFilter={channelFilter}
        selectedChannel={channel}
        selectChannel={selectChannel}
      />
    )
  );
};

export default Recommendations;
