import 'react-perfect-scrollbar/dist/css/styles.css';
import CssBaseline from '@mui/material/CssBaseline';
import React from 'react';
import { useRoutes } from 'react-router-dom';

import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';

import GlobalStyles from 'utils/styleUtils/GlobalStyles';
import theme from './theme';
import routes from './routes';

const App = () => {
  const content = useRoutes(routes);

  return (
    <StyledEngineProvider injectFirst>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        {content}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
