import { Map } from 'immutable';

export const getRoot = state => state.get('app') || Map();

/* AGENT */

export const getAgentData = state => getRoot(state).get('agent');

export const getAgentRoles = state => getAgentData(state).get('roles');

export const getAgentName = state => getAgentData(state).get('name');

export const getAgentEmail = state => getAgentData(state).get('email_address');

export const getAgentUsername = state => getAgentData(state).get('username');

export const getAgentPermissions = state => getAgentData(state).get('permissions');

export const getIsLoggedIn = state => !!getAgentData(state).get('username');

/* PRODUCTS */

export const getProducts = state => getRoot(state).get('products') || Map();

export const getRawProductData = state => getProducts(state).get('data') || Map();

export const getProductByCode = (state, code) => getProducts(state).get(code) || Map();

/* UI */

export const getUIRoot = state => getRoot(state).get('ui') || Map();

export const getIsLoading = state => getRoot(state).getIn(['ui', 'isLoading']);

export const getSnackbar = state => getRoot(state).getIn(['ui', 'snackbar']);

export const getNotification = state => getRoot(state).getIn(['ui', 'notification']);

/* BATCHING THINGS */

export const getBatch = state => getUIRoot(state).get('batch');

export const getBatchTotal = state => getBatch(state).get('total');

export const getBatchCounter = state => getBatch(state).get('counter');

export const getBatchProgress = state => `${getBatchCounter(state)}/${getBatchTotal(state)}`;

export const getBatchRetryState = state => getBatch(state).get('retry');

export const getBatchItems = state => getBatch(state).get('items');
