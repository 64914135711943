import { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { arrayToObject, objectToArray } from 'utils/transformUtils';

const useFilteredDataGrid = (data, filterNames) => {
  const dispatch = useDispatch();
  const filterObj = filterNames.reduce((a, b) => ((a[b] = []), a), {});

  const [filteredData, setFilteredData] = useState(data);
  const [filters, setFilters] = useState(filterObj);
  const [rows, setRows] = useState([]);
  const [edits, setEdits] = useState([]);
  const [customFilterFn, setCustomFilterFn] = useState(null);
  const [editCount, setCount] = useState(0);

  const updateData = useCallback(() => {
    let dataFiltered = filteredData.filter(row =>
      Object.keys(filters).every(key => {
        if (filters[key].length === 0) {
          return row;
        } else if (Array.isArray(row[key])) {
          return row[key].includes(filters[key]);
        } else if (Array.isArray(filters[key])) {
          return filters[key].includes(row[key]);
        }
        return filters[key].toString() == row[key].toString();
      })
    );

    // Apply filter functions
    if (customFilterFn) {
      for (const customFilter of Object.values(customFilterFn)) {
        if (typeof customFilter === 'function') {
          dataFiltered = dataFiltered.filter(item => customFilter(item));
        }
      }
    }

    const dataObj = arrayToObject(dataFiltered);

    for (const edit of edits) {
      if (dataObj[edit.id]) {
        dataObj[edit.id] = {
          ...dataObj[edit.id],
          ...edit.fields,
        };
      }
    }

    setRows(objectToArray(dataObj));
  }, [filters, filteredData, edits, customFilterFn]);

  const onFilterApply = ({ target: { name, value } }) => {
    const newFilterObj = { ...filters, [name]: value };
    setFilters(newFilterObj);
    updateData();
    return [filters];
  };

  const onCustomFilterFnApply = fn => {
    setCustomFilterFn({ fn });
  };

  const commitEdit = ({ id, field, value }) => {
    const row = filteredData.find(data => data.id === id);
    const originalValue = JSON.stringify(row[field] || '');
    if (row && originalValue === JSON.stringify(value)) {
      setCount(editCount - 1);
      const newEdits = edits.filter(edit => {
        if (edit.id === id) {
          delete edit.fields[field];
        }
        return Object.keys(edit.fields).length > 0;
      });
      setEdits(newEdits);
      return [edits];
    }
    let newEdits = [{ id: id, fields: { [field]: value } }];
    if (edits.length > 0) {
      const update = edits.find(edit => edit.id === id);
      if (update) {
        let newCount = editCount + 1;
        newEdits = edits.map(edit => {
          if (id === edit.id) {
            if (field in edit.fields) {
              newCount--;
            }
            return { id: id, fields: { ...update.fields, [field]: value } };
          }
          return edit;
        });
        setCount(newCount);
      } else {
        setCount(editCount + 1);
        newEdits = [...edits, ...newEdits];
      }
    } else {
      setCount(editCount + 1);
    }
    setEdits(newEdits);
    return [edits];
  };

  const handleSave = async saveData => {
    const error = await dispatch(saveData(edits));
    if (!error) {
      setCount(0);
      setEdits([]);
    }
  };

  const handleCancel = () => {
    setCount(0);
    setEdits([], setFilteredData(data));
    updateData();
  };

  return {
    rows,
    edits,
    setFilteredData,
    filteredData,
    updateData,
    filters,
    onFilterApply,
    setCount,
    editCount,
    commitEdit,
    handleSave,
    handleCancel,
    onCustomFilterFnApply,
  };
};

export default useFilteredDataGrid;
