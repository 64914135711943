export const removeUnderscores = (str, space = true) => {
  const sp = space ? ' ' : '';
  return str ? str.toString().replace(/_/g, sp) : str;
};

export const formatCellValue = value => {
  if (value === true) return 'Yes';
  if (value === false) return 'No';
  return removeUnderscores(value);
};
