import React from 'react';
import PropTypes from 'prop-types';

import { Box, Button, Container, Divider } from '@mui/material';
import { DataGrid, GridOverlay } from '@mui/x-data-grid';

import SyncIcon from '@mui/icons-material/Sync';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';

import FilterBar from 'views/common/FilterBar';
import SaveBar from 'views/common/SaveBar';

// TODO: fix styling
const ShadeDataView = ({
  columns,
  rows,
  filterProperties,
  selectedFilters,
  onFilter,
  onEdit,
  onSave,
  unsavedEdits,
  onCancel,
  productSync,
  selectChannel,
  selectedChannel,
  channelFilter,
  canAdd,
  onAdd,
  showAdd,
}) => {
  const buttons = [
    {
      text: 'Discard',
      onClick: onCancel,
      variant: 'outlined',
      color: 'dark',
    },
    {
      text: 'Save',
      onClick: onSave,
      variant: 'contained',
      color: 'secondary',
    },
  ];

  return (
    <Container maxWidth="xl">
      <Box sx={{ pt: 3 }}>
        {channelFilter && channelFilter.length !== 0 ? (
          <>
            <FilterBar
              filterProperties={channelFilter}
              onFilter={selectChannel}
              justify="flex-end"
              selectedFilters={selectedChannel}
            />
            <Divider light sx={{ mb: 2 }} />
          </>
        ) : null}
        <div style={{ float: 'right' }}>
          <Button onClick={productSync} variant="contained" startIcon={<SyncIcon />}>
            Sync Products
          </Button>
          {canAdd ? (
            <>
              {showAdd ? (
                <Button style={{ marginLeft: '15px' }} onClick={onAdd} variant="contained">
                  <AddIcon />
                </Button>
              ) : (
                <Button style={{ marginLeft: '15px' }} onClick={onAdd} variant="contained">
                  <CheckIcon />
                </Button>
              )}
            </>
          ) : null}
        </div>
        <FilterBar
          filterProperties={filterProperties}
          selectedFilters={selectedFilters}
          onFilter={onFilter}
        />
        <div style={{ height: 400, width: '100%' }}>
          <div style={{ display: 'flex', height: '100%' }}>
            <div style={{ flexGrow: 1, marginBottom: '30px' }}>
              <DataGrid
                className="root"
                autoHeight
                disableVirtualization
                disableColumnFilter
                hideFooter={true}
                columns={columns}
                rows={rows}
                onCellEditCommit={onEdit}
                components={{
                  NoRowsOverlay: () => <GridOverlay>No results found.</GridOverlay>,
                }}
              />
              <Box sx={{ height: '56px' }} />
            </div>
          </div>
        </div>
      </Box>
      {unsavedEdits > 0 ? <SaveBar buttons={buttons} unsavedEdits={unsavedEdits} /> : null}
    </Container>
  );
};

ShadeDataView.propTypes = {
  columns: PropTypes.array,
  rows: PropTypes.array,
  filterProperties: PropTypes.array,
  selectedFilters: PropTypes.object,
  onFilter: PropTypes.func,
  onEdit: PropTypes.func,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  productSync: PropTypes.func,
  unsavedEdits: PropTypes.number,
  channelFilter: PropTypes.array,
  selectChannel: PropTypes.func,
  selectedChannel: PropTypes.object,
  canAdd: PropTypes.bool,
  onAdd: PropTypes.func,
  showAdd: PropTypes.bool,
};

export default ShadeDataView;
