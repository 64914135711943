export const getFormChanges = async (values, changes) => {
  let edits = {};
  Object.keys(changes).forEach(key => {
    const edit = values[key];
    if (edit !== undefined) Object.defineProperty(edits, key, { value: edit });
  });
  return edits;
};

export const formatSelection = (list, value) => {
  return list.find(item => item.value === value)?.label || '--';
};

export const sort = (list, a, b) => {
  const aLabel = list.find(item => item.value === a).label;
  const bLabel = list.find(item => item.value === b).label;
  if (aLabel === bLabel) {
    return 0;
  } else if (aLabel > bLabel) {
    return 1;
  }
  return -1;
};
