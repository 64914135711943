import React from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  CardContent,
  Grid,
  IconButton,
  MenuItem,
  ListSubheader,
  Paper,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

import { FieldArray } from 'react-final-form-arrays';

import RuleOutputCompare from './RuleOutputCompare';

import { TextField } from 'mui-rff';

const RuleOutput = ({ factSelect, opSelect, outputSelect, fields, push, actionSelect }) => {
  return (
    <CardContent>
      {fields.map((action, i) => (
        <React.Fragment key={i}>
          <Paper sx={{ pl: 1 }}>
            <Grid container justifyContent="space-between">
              <Grid container item justifyContent="space-between" alignItems="center" xs={11}>
                <Grid item container xs={12} spacing={0.5}>
                  <Grid item xs={4}>
                    <TextField
                      label="Output"
                      name={`${action}.output`}
                      margin="normal"
                      size="small"
                      id="select"
                      select
                    >
                      {outputSelect.map(({ key, label }) => (
                        <MenuItem key={key} value={key}>
                          {label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="Action"
                      name={`${action}.action`}
                      margin="normal"
                      size="small"
                      id="select"
                      select
                    >
                      {actionSelect.map(({ key, label }) => (
                        <MenuItem key={key} value={key}>
                          {label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={4}>
                    {fields.value[i]?.action === 'SET_STATIC' ? (
                      <TextField
                        label="Value"
                        name={`${action}.value`}
                        margin="normal"
                        size="small"
                      />
                    ) : fields.value[i]?.action === 'SET_FACT' ? (
                      <TextField
                        label="Fact"
                        name={`${action}.fact`}
                        margin="normal"
                        size="small"
                        id="select"
                        select
                      >
                        <ListSubheader>Outputs</ListSubheader>
                        {outputSelect.map(({ key, label }) => (
                          <MenuItem key={key} value={key}>
                            {label}
                          </MenuItem>
                        ))}
                        <ListSubheader>Inputs</ListSubheader>
                        {factSelect.map(fact => (
                          <MenuItem key={`${fact}`} value={fact}>
                            {fact}
                          </MenuItem>
                        ))}
                      </TextField>
                    ) : fields.value[i]?.action === 'SET_FORMULA' ? (
                      <TextField
                        label="Input"
                        name={`${action}.fact`}
                        margin="normal"
                        size="small"
                        id="select"
                        select
                      >
                        {factSelect.map(fact => (
                          <MenuItem key={`${fact}`} value={fact}>
                            {fact}
                          </MenuItem>
                        ))}
                      </TextField>
                    ) : (
                      <TextField
                        label="Operator"
                        name={`${action}.operator`}
                        margin="normal"
                        size="small"
                        id="select"
                        select
                      >
                        {opSelect.map(
                          op =>
                            op.type === 'COMPARE' && (
                              <MenuItem key={`${op.value}`} value={op.value}>
                                {op.label}
                              </MenuItem>
                            )
                        )}
                      </TextField>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={1} sx={{ textAlign: 'center', pt: 1.5 }}>
                <IconButton edge="end" aria-label="delete" onClick={() => fields.remove(i)}>
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Paper>
          <Grid container sx={{ mb: 3 }}>
            <Grid item container xs={11}>
              {fields.value[i]?.action === 'SET_FORMULA' ||
              fields.value[i]?.action === 'SET_COMPARE' ? (
                fields.value[i]?.action === 'SET_FORMULA' ? (
                  <Grid item container xs={12} spacing={0.5} justifyContent="flex-end">
                    <Grid item xs={3}>
                      <TextField
                        label="Operator"
                        name={`${action}.operator`}
                        margin="normal"
                        size="small"
                        id="select"
                        select
                      >
                        {opSelect.map(
                          op =>
                            op.type === 'OUTPUT' && (
                              <MenuItem key={`${op.value}`} value={op.value}>
                                {op.label}
                              </MenuItem>
                            )
                        )}
                      </TextField>
                    </Grid>
                    <Grid item xs={1}>
                      <TextField
                        label="Value"
                        name={`${action}.value`}
                        margin="normal"
                        size="small"
                      />
                    </Grid>
                  </Grid>
                ) : (
                  <Grid item container xs={12}>
                    <FieldArray name={`${action}.formula`}>
                      {({ fields }) => {
                        if (fields.length === 0)
                          push(`${action}.formula`, { action: null }, { action: null });
                        return fields.map((formula, i) => (
                          <RuleOutputCompare
                            key={i}
                            index={i}
                            formula={formula}
                            fields={fields}
                            actionSelect={actionSelect}
                            factSelect={factSelect}
                            opSelect={opSelect}
                            outputSelect={outputSelect}
                          />
                        ));
                      }}
                    </FieldArray>
                  </Grid>
                )
              ) : null}
            </Grid>
            <Grid item xs={1} />
          </Grid>
        </React.Fragment>
      ))}
      <Button
        variant="outlined"
        startIcon={<AddIcon />}
        onClick={() =>
          push('actions', { output: null, formula: [{ action: null }, { action: null }] })
        }
      >
        Add
      </Button>
    </CardContent>
  );
};

RuleOutput.propTypes = {
  fields: PropTypes.object,
  factSelect: PropTypes.array,
  push: PropTypes.func,
  opSelect: PropTypes.array,
  outputSelect: PropTypes.array,
  actionSelect: PropTypes.array,
};

export default RuleOutput;
