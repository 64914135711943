import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import { AppBar, Toolbar, Typography, IconButton, Box, Tab, Tabs, Button } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import UserIcon from '@mui/icons-material/AccountCircle';
import AppsIcon from '@mui/icons-material/Apps';

import { getAgentName } from 'state/redux/app/selectors';
import { clearCache } from 'state/redux/app/operations';

import Logo from './Logo';
import { styled } from '@mui/material/styles';

// TODO: fix all this nonsense

const MRAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: '#FFF',
  color: theme.palette.text.primary,
}));

const MRTabs = styled(Tabs)({
  minHeight: '64px',
});

const MRTab = styled(Tab)({
  minHeight: '64px',
  textTransform: 'none',
});

const Header = ({ onMobileNavOpen, isMobileNavOpen, ...rest }) => {
  const dispatch = useDispatch();
  const agentName = useSelector(getAgentName);
  const location = useLocation();
  const routes = [
    {
      value: 'recommendations',
      path: '/recommendations/level/2?recSetId=1',
    },
    {
      value: 'rules',
      path: '/rules',
    },
    {
      value: 'shades',
      path: '/shades',
    },
  ];

  const clearCacheClick = () => {
    dispatch(clearCache());
  };

  return (
    <MRAppBar
      elevation={1}
      sx={{ zIndex: theme => (!isMobileNavOpen ? theme.zIndex.drawer + 1 : '') }}
      {...rest}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          onClick={onMobileNavOpen}
          size="large"
          sx={{
            mr: 2,
            display: { xs: 'flex', lg: 'none' },
          }}
          edge="start"
        >
          <MenuIcon />
        </IconButton>
        <Link to="/">
          <Logo />
        </Link>
        <Box sx={{ flexGrow: 1 }}>
          <MRTabs value={location.pathname.split('/')[1]} centered>
            <MRTab
              label="Shade Logic"
              component={Link}
              to={routes[0].path}
              value={routes[0].value}
            />
            <MRTab
              label="Target Level Logic"
              component={Link}
              to={routes[1].path}
              value={routes[1].value}
            />
            <MRTab
              label="Shade Configuration"
              component={Link}
              to={routes[2].path}
              value={routes[2].value}
            />
          </MRTabs>
        </Box>
        <Button onClick={clearCacheClick} variant="contained">
          Clear cache
        </Button>
        <IconButton color="inherit" onClick={() => {}} size="large">
          <AppsIcon />
        </IconButton>
        <IconButton color="inherit" onClick={() => {}} size="large">
          <UserIcon />
        </IconButton>
        <Typography variant="body1" component="div">
          {agentName}
        </Typography>
      </Toolbar>
    </MRAppBar>
  );
};

Header.propTypes = {
  onMobileNavOpen: PropTypes.func,
  isMobileNavOpen: PropTypes.bool,
};

export default Header;
