import React from 'react';
import PropTypes from 'prop-types';

import { MenuItem, Box, Chip, Checkbox, ListItemText } from '@mui/material';

import { TextField } from 'mui-rff';

const MultipleSelect = ({ items, constraint, fields }) => {
  const value = Array.isArray(fields.value) ? fields.value : fields.value ? [fields.value] : [];
  return (
    <TextField
      label="Value"
      name={`${constraint}.value`}
      margin="normal"
      size="small"
      id="select"
      value={value}
      select
      SelectProps={{
        renderValue: selected => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map(value => (
              <Chip key={value} label={`${value}`} size="small" />
            ))}
          </Box>
        ),
        multiple: true,
      }}
    >
      {items(fields.fact).map(val => (
        <MenuItem key={`${val.value}`} value={val.value} margin="normal">
          <Checkbox checked={value.includes(val.value)} />
          <ListItemText primary={val.value.toString()} />
        </MenuItem>
      ))}
    </TextField>
  );
};

MultipleSelect.propTypes = {
  fields: PropTypes.object,
  constraint: PropTypes.string,
  items: PropTypes.func,
};

export default MultipleSelect;
