import React from 'react';
import PropTypes from 'prop-types';

import {
  Container,
  Grid,
  Box,
  List,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import RuleDetails from '../containers/RuleDetails';
import SaveBar from 'views/common/SaveBar';

// TODO: make this user configurable
const dense = false;

const RulesView = ({
  ruleSet,
  rules,
  selectedId,
  addRule,
  saveRule,
  drop,
  hasUnsavedEdits,
  discardAllChanges,
  renderRules,
  unsavedRule,
  saveRuleSet,
}) => {
  const buttons = [
    { text: 'Discard All Changes', color: 'dark', variant: 'outlined', onClick: discardAllChanges },
    { text: 'Save Rule Set', onClick: saveRuleSet },
    { text: 'Make Rule Set Active', color: 'primary' },
  ];

  return (
    <Container maxWidth="xl" sx={{ mb: 4 }}>
      <Box sx={{ pt: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <Card>
              <CardHeader
                title={ruleSet.name}
                subheader={ruleSet.description}
                action={
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<AddIcon />}
                    onClick={() => addRule()}
                  >
                    Add
                  </Button>
                }
              ></CardHeader>
              <CardContent>
                <List dense={dense} ref={drop}>
                  <Divider />
                  {rules && rules.length > 0 ? rules.map((rule, i) => renderRules(rule, i)) : null}
                </List>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={7}>
            {selectedId !== null ? (
              <RuleDetails
                rule={rules.find(({ id }) => id === selectedId)}
                saveRule={saveRule}
                unsavedRule={unsavedRule}
              />
            ) : (
              <Card>
                <CardHeader title={`No Rule Selected`} />
              </Card>
            )}
          </Grid>
        </Grid>
      </Box>
      {hasUnsavedEdits ? <SaveBar buttons={buttons} /> : null}
    </Container>
  );
};

RulesView.propTypes = {
  ruleSet: PropTypes.object,
  rules: PropTypes.array,
  renderRules: PropTypes.func,
  selectedId: PropTypes.number,
  addRule: PropTypes.func,
  saveRule: PropTypes.func,
  drop: PropTypes.func,
  hasUnsavedEdits: PropTypes.bool,
  discardAllChanges: PropTypes.func,
  saveRuleSet: PropTypes.func,
  unsavedRule: PropTypes.func,
};

export default RulesView;
