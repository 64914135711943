export const recArrayToObject = recs => {
  let formattedRecs = {};

  recs.forEach(rec => {
    formattedRecs[rec.id] = { ...rec };
  });

  return formattedRecs;
};

export const recObjectToArray = recs => {
  return Object.values(recs);
};
